import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { QmAutocompleteData } from 'src/uikit/qm-autocomplete/qm-autocomplete-data';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { GroupService } from 'src/app/users/services/group.service';
import { Group } from 'src/app/users/interfaces/group';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  items: MenuItem[] = [];
  itemsmenu: QmAutocompleteData[] = [];
  listaSuggestions: QmAutocompleteData[] = [];
  moduleValue!: string;
  groupUser!: Group;

  constructor(
    private authService: AuthService,
    private router: Router,
    private groupService: GroupService
  ) {

  }

  ngOnInit() {
    this.getPerfilUser();
    
  }

  genereatedMenu(){
    this.items = [
      {
          label: 'Módulos',
          icon: 'pi pi-sitemap',
          items: [
              {label: 'Usuários', icon: 'pi pi-users', routerLink: 'users/user', visible: this.authService.isAdmin},
              {label: 'Configuração', icon: 'pi pi-user-edit', visible: this.authService.isAdmin,
                items: [
                  {label: 'Configuração AI', icon: 'pi pi-user-edit', routerLink: 'setup', visible: this.authService.isAdmin },
                  {label: 'Configuração LDAP', icon: 'pi pi-user-edit', routerLink: 'ldapConfig', visible: this.authService.isAdmin }
                ]
              },
              {label: 'Modelos', icon: 'pi pi-book', routerLink: 'model', visible: this.authService.isAdmin },
              {label: 'Rastreamento de prontuário', icon: 'pi pi-book',
                items: [
                  {label: 'Categoria dos itens de prontuário', icon: 'pi pi-book', routerLink: 'assunto', visible: true },
                  {label: 'Itens de prontuário', icon: 'pi pi-book', routerLink: 'subfaturamento', visible: true },
                  {label: 'Hierarquia de itens', icon: 'pi pi-book', routerLink: 'subfaturamento-fluxograma', visible: true }
                ]},
              {label: 'Regras de validação', icon: 'pi pi-book',
                items: [
                  {label: 'Tipos de Regras', icon: 'pi pi-book', routerLink: 'tipoRegraValidacao', visible: true },
                  {label: 'Regras', icon: 'pi pi-book', routerLink: 'regraValidacao', visible: true },
                  {label: 'Associação de Regras', icon: 'pi pi-book', routerLink: 'associacaoRegraUnidadeSistema', visible: true },
                  {label: 'Hierarquia de Regras', icon: 'pi pi-book', routerLink: 'fluxograma', visible: true },
                  {label: 'Relatorios de Regras', icon: 'pi pi-book', routerLink: 'relatorio', visible: true }
                ]}
              
          ]
      }
    ];

    var menuListFlat = this.flatMenuStructure(JSON.parse(JSON.stringify(this.items)));

    for (const item of menuListFlat) {
        if (item.routerLink !== undefined) {
          this.itemsmenu.push({label: item.label?? 'Item', value: {url: item.routerLink, visible: item.visible }})
        }
    }

  }
  getPerfilUser(){
    this.groupService.getGroupByUser().subscribe({
      next: (res) => {
        this.authService.setGroupUser(res);
        //console.log(this.groupUser.name);
        this.genereatedMenu();
      },
      error: (err) => console.log(err.error.title)
    })
  }
  onSearchData(event: any): void {
    this.listaSuggestions = this.itemsmenu.filter(el => el.label?.trim().toLowerCase().indexOf(event.query?.trim().toLowerCase()) !== -1);
  }

  onSelectData(event: any) {
      this.router.navigate([event.value.url]);
  }

  flatMenuStructure(itemTree: MenuItem[]): MenuItem[] {
    let items: MenuItem[] = [];
    const flattenMembers = itemTree.map(m => {
      if (m.items && m.items.length) {
        items = [...items, ...m.items];
      }
      return m;
    });

    return flattenMembers.concat(items.length ? this.flatMenuStructure(items) : items);
  };

  logout() {
    this.authService.logout();
  }
}
