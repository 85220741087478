import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';
import { QmSelectData } from './qm-select-data';

@Component({
  selector: 'qm-select[titleMessage][optionValues]',
  template: `
  <qm-input-title [titleMessage]="titleMessage" [optionalField]="!this.required"></qm-input-title>
  <p-dropdown [options]="optionValues" optionLabel="label" optionValue="value" [placeholder]="placeHolderMessage"
   [autoDisplayFirst]="false" [showClear]="showClear" [disabled]="disabled"
   autoWidth="false" [filter]="enableFilter" [resetFilterOnHide]="true"
   [ngModel]="controlValue"
   (ngModelChange)="onModelChange($event)"
   (onChange)="onChangeValue($event)"
   (onBlur)="onTouched()"
   [ngClass]="{'ng-dirty ng-invalid': invalidInput }"
   [style]="{'width':'100%','minWidth':'100%','margin-top': '0.5rem', 'margin-bottom' : '0.25rem'}">
  </p-dropdown>
  <qm-input-legend [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>
  `,
  styleUrls: ['./qm-select.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmSelectComponent),
        multi: true
    }
]
})
export class QmSelectComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue!: unknown | undefined;
  onChanged = (_val: any) => {};
  onTouched = () => {}

  @Input()
  placeHolderMessage!: string;

  @Input()
  optionValues!: QmSelectData[];

  @Input()
  showClear: boolean = true;

  @Input()
  enableFilter: boolean = true;

  @Output()
  changeEvent = new EventEmitter<any>();

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.setupLegendMessage();
  }

  writeValue(val: any): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(event: any) {
    this.onChanged(event);
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.changeEvent.emit(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
