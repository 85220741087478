import { Component, Input } from '@angular/core';

@Component({
  selector: 'qm-input-title',
  template: `
    <span style="display: flex; gap: 5px;">
      <qm-label sizeFont='normal' styleColor="plain" styleFont="bold" [labelMessage]="titleMessage"></qm-label>
      <qm-label *ngIf="optionalField" sizeFont='normal' styleColor="legend" styleFont="normal" labelMessage="(Opcional)"></qm-label>
    </span>
  `,
  styleUrls: ['./qm-input-title.component.css']
})
export class QmInputTitleComponent {

  @Input()
  titleMessage: string = "";

  @Input()
  optionalField: boolean = false;

}
