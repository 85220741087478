import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UikitModule } from 'src/uikit/uikit.module';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { PageforbiddenComponent } from './components/pageforbidden/pageforbidden.component';
import { PagesystemerrorComponent } from './components/pagesystemerror/pagesystemerror.component';
import { TranslocoModule } from '@ngneat/transloco';

import { MenubarModule } from 'primeng/menubar';


@NgModule({
  declarations: [
    HeaderComponent,
    PagenotfoundComponent,
    PageforbiddenComponent,
    PagesystemerrorComponent
  ],
  imports: [
    MenubarModule,
    CommonModule,
    RouterModule,
    UikitModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule
  ],
  exports: [
    HeaderComponent,
    MenubarModule,
    FormsModule,
    ReactiveFormsModule,
    UikitModule
  ]
})
export class TemplateModule { }
