import { ChatState, MessageData, MessageMediaContent, MessageOptions, MessageUpdate } from './message.interface';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: null
})
export class ChatService {
  private commandSubject = new BehaviorSubject<MessageData | undefined>(undefined);
  private messageSubject = new BehaviorSubject<MessageData | undefined>(undefined);
  private messageUpdate = new BehaviorSubject<MessageUpdate | undefined>(undefined);

  createSenderMessage(content: string) {
    const idmsg = `msg_send_${new Date().getTime()}`;
    this.messageSubject.next({id: idmsg, content: content, datetime: new Date(), sender: true, state: 'sending'})
    return this.createReceiverMessage();
  }

  createMediaMessage(mediaContent: MessageMediaContent) {
    const idmsg = `msg_send_${new Date().getTime()}`;
    this.messageSubject.next({id: idmsg, content: "", mediacontent: mediaContent, datetime: new Date(), sender: true, state: 'sending'})
    return this.createReceiverMessage();
  }

  private createReceiverMessage() {
    const idmsg = `msg_recv_${new Date().getTime()}`;
    this.messageSubject.next({id: idmsg, content: "", datetime: new Date(), sender: false, state: 'sending'})
    return idmsg;
  }

  createCommandMessage(contentString: string) {
    const idmsg = `msg_recv_${new Date().getTime()}`;
    this.commandSubject.next({id: idmsg, content: contentString, datetime: new Date(), sender: false, state: 'finish'})
  }

  writeReceiverSampleMessage(msgId: string, content: string, state: ChatState, options?: MessageOptions) {
    this.messageUpdate.next({id: msgId, content: content, typing: false, state: state, options: options, });
  }

  writeReceiverTypingMediaMessage(msgId: string, content: string, state: ChatState, mediaContent: MessageMediaContent, options?: MessageOptions) {
    this.messageUpdate.next({id: msgId, content: content, typing: true, state: state, mediacontent: mediaContent, options: options});
  }

  writeReceiverSampleMediaMessage(msgId: string, content: string, state: ChatState, mediaContent: MessageMediaContent, options?: MessageOptions) {
    this.messageUpdate.next({id: msgId, content: content, typing: false, state: state, mediacontent: mediaContent, options: options, });
  }

  writeReceiverTypingMessage(msgId: string, content: string, state: ChatState, options?: MessageOptions) {
    this.messageUpdate.next({id: msgId, content: content, typing: true, state: state, options: options});
  }

  writeReceiverEraseMessage(msgId: string, content: string) {
    this.messageUpdate.next({id: msgId, content: content, typing: false, state: 'erase'});
  }

  writeReceiverErrorMessage(msgId: string, content: string) {
    this.messageUpdate.next({id: msgId, content: content, typing: false, state: 'error'});
  }

  public createdMessage$() {
      return this.messageSubject;
  }

  public updatedMessage$() {
    return this.messageUpdate;
  }

  public commandMessage$() {
    return this.commandSubject;
  }

}
