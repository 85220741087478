import { Component, Input } from '@angular/core';
import { QmOrderlistData } from './qm-orderlist-data';

@Component({
  selector: 'qm-orderlist[valueList][headerList]',
  template: `

  <p-orderList [value]="valueList"
               [header]="headerList"
               [responsive]="false">
    <ng-template let-rowData pTemplate="item">
        <div>
            <div> {{rowData.label}} </div>
        </div>
    </ng-template>
  </p-orderList>

  `,
  styleUrls: ['./qm-orderlist.component.css']
})
export class QmOrderlistComponent {

  @Input()
  valueList: QmOrderlistData[] = [];

  @Input()
  headerList!: string;

}


