import { Component, Input } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { QmCheckboxData } from './qm-checkbox-data';

@Component({
  selector: 'qm-checkbox-group[titleMessage][optionValues][groupName]',
  template: `
  <qm-input-title [titleMessage]="titleMessage"></qm-input-title>
  <div [class]= "direction === 'horizontal'? 'flex flex-column mt-2 mb-4' : 'flex flex-row mt-2 mb-4'" style="gap: 0.5rem">
    <div *ngFor="let option of optionValues">
        <div class="flex align-items-center">
        <p-checkbox [inputId]="option.key" [name]="groupName" [value]="option.value" [formControl]="controlValue" class="mr-2"></p-checkbox>
        <qm-label [attachComponent]="option.key"  [labelMessage]="option.name" sizeFont="normal"></qm-label>
        </div>
    </div>
  </div>
  `,
  styleUrls: ['./qm-checkbox-group.component.css'],
  viewProviders: [
    {
        provide: ControlContainer,
        useExisting: FormGroupDirective
    }
]
})
export class QmCheckboxGroupComponent {

  constructor() { }

  @Input()
  titleMessage="";

  @Input()
  controlValue!: FormControl;

  @Input()
  direction: 'horizontal' | 'vertical' = "horizontal";

  @Input()
  groupName!: string;

  @Input()
  optionValues!: QmCheckboxData[]

}
