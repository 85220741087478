import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';

@Component({
  selector: 'qm-editor[titleMessage]',
  template: `
   <qm-input-title [titleMessage]="titleMessage" [optionalField]="!this.required"></qm-input-title>
    <p-editor
    [(ngModel)]="controlValue"
    [style]="{'height': editorHeight}"
    [readonly]="readonlyEditor"
    [disabled]="disabled"
    (onTextChange)="onChangeValue($event)"
    [ngClass]="{'ng-dirty ng-invalid': invalidInput}"
    (onSelectionChange)="selectionChangeEvent.emit($event)">
  </p-editor>
  <qm-input-legend [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>
  `,
  styleUrls: ['./qm-editor.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmEditorComponent),
        multi: true
    }
  ]
})
export class QmEditorComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue!: string | undefined;
  onChanged = (_val: string) => {};
  onTouched = () => {}

  @Input()
  editorHeight: string = '300px';

  @Input()
  readonlyEditor: boolean = false;

  @Output()
  textChangeEvent = new EventEmitter<any>();

  @Output()
  selectionChangeEvent = new EventEmitter<any>();

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.setupLegendMessage();
  }

  writeValue(val: string): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.onChanged(event.htmlValue);
    this.textChangeEvent.emit(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }



}
