export const environment = {
  production: false,
  apiGatewayUrl: window['env']['apiGatewayUrl'] || 'http://127.0.0.1:8080/backend',
  oAuthServerUrl: window['env']['oAuthServerUrl'] || 'http://127.0.0.1:8080/backend',
  redirectUriAfterLogin: window['env']['redirectUriAfterLogin'] ||'http://localhost:4200/auth',
  endPointSufix:'/api/v3',
  endPointPbi: '/pbi',
  endPointSetup: '/configuration_ai',
  endPointModel: '/model_ai',
  endPointChat: '/chat_ai',
  endPointUser: '/user',
  endPointMDM: '/survey',
  endPointOrgstr: '/organizational-structure',
  endPointDivisionType: '/division-type',
  endPointDivision: '/division',
  endPointJobTitle: '/job-title',
  endPointPerson: '/person',
  endPointGroup: '/group',
  endPointPolicy: '/policy',
  endPointFeature: '/feature',
};
