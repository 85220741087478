import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.oAuthServerUrl,
  redirectUri: environment.redirectUriAfterLogin,
  clientId: 'qmfrontend',
  dummyClientSecret: 'secret',
  responseType: 'code',
  scope: 'openid',
  requireHttps: false,
  useHttpBasicAuth: true
};

