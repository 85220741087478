import { Component, Input } from '@angular/core';

@Component({
  selector: 'qm-fileupload',
  template: `
    <p-fileUpload name="myfile[]"
        [auto]="autoUpload"
        [multiple]="multipleFiles"
        [accept]="acceptPattern"
        (uploadHandler)="myUploader($event)"
        [customUpload]="true"
        [maxFileSize]="fileSize">
  </p-fileUpload>
  `,
  styleUrls: ['./qm-fileupload.component.css']
})
export class QmFileuploadComponent {

  @Input()
  fileSize: number = 1048576;

  @Input()
  acceptPattern: string = "";

  @Input()
  multipleFiles: boolean = false;

  @Input()
  autoUpload: boolean = false;

  myUploader(event: any) {
    console.log(event);
  }


}
