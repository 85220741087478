import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlight'
})
export class AutocompleteHighlightPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string, search: any) {
    const pattern = search
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
      .split(' ')
      .filter((t: string | any[]) => t.length > 0)
      .join('|');
    const regex = new RegExp(pattern, 'gi');

    return search ? this.sanitizer.bypassSecurityTrustHtml(text.replace(regex, match => `<b>${match}</b>`)) : text;
  }

}
