import { Component } from '@angular/core';

@Component({
  selector: 'qm-tag',
  template: `
    <p-tag severity="primary" value="Lorem Ipsum"></p-tag>
    <p-tag severity="success" value="Lorem Ipsum"></p-tag>
    <p-tag severity="info" value="Lorem Ipsum"></p-tag>
    <p-tag severity="warning" value="Lorem Ipsum"></p-tag>
    <p-tag severity="danger" value="Lorem Ipsum"></p-tag>
  `,
  styleUrls: ['./qm-tag.component.css']
})
export class QmTagComponent {


}
