import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, ViewChild, Inject} from '@angular/core';
import { ChatMode, ChatUserData } from './chat-user.interface';
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { NgScrollbar } from 'ngx-scrollbar';
import { ChatService } from './chat.service';
import { MessageData, MessageMediaContent, MessageUpdate } from './message.interface';
import { DOCUMENT } from '@angular/common';
import {Observable, concatMap, defer, delay, filter, finalize, interval, map, of, take, tap} from 'rxjs';
import { ChatEventData } from './chat-event.interface';
import { LOAD_PLACEHOLDER} from './chat-placeholder';
import { AudioRecordingService } from './chat-audio-record.service';
import { ChatPowerBiService } from './chat-powerbi.service';
import { PowerBiComponent } from './powerbi.interface';

@Component({
  selector: 'qm-chat[messageUser]',
  template: `

    <div class="chatContainer" *ngIf="!this.dashboardView">

      <ng-container *ngIf="!hideHeader">
        <div [style.background-color]="this.backgroundColorChat" class="chatHeader">
          <div class="chatHeaderUserInfo">
            <div class="flex flex-column mr-2">
              <p-avatar [image]="messageUser.avatar" size="large" shape="circle"></p-avatar>
            </div>
            <div class="flex flex-column">
              <qm-label [labelMessage]="messageUser.username" styleFont="bold" class="mb-1" [styleColor]="darkMode? 'white': 'black'"></qm-label>
              <qm-label [labelMessage]="messageUser.online? 'Disponível' : 'Offline'" sizeFont="small" [styleColor]="darkMode? 'white': 'black'"></qm-label>
            </div>
          </div>

          <div class="chatHeaderButtons">
            <qm-button [buttonType]="chatStyle" buttonIconAction="arrow-down" [onlyIcon]="true" [rounded]="true"
                                                (clickEvent)="scrollbarRef.scrollTo({ bottom: 0 })"></qm-button>
          </div>
        </div>
      </ng-container>

      <div [style.background-color]="this.backgroundColorChat" class="chatBody">
          <ng-scrollbar [ngClass]="scrollBarResolver()" #scrollbarRef>
            <qm-message [msgUser]="messageUser" [msgData]="message" [chatStyle]="chatStyle" [darkMode]="darkMode" [playAudio]="chatMode.autoPlay" *ngFor="let message of messageList" />
          </ng-scrollbar>
      </div>

      <div [style.background-color]="this.backgroundColorChat" class="chatFooter">

        <ng-container *ngIf="chatMode.ragApi">
          <div class="grid">
            <div class="flex justify-content-center align-items-center col-2">
              <qm-label sizeFont='normal' [styleColor]="darkMode? 'white': 'black'" styleFont="bold" labelMessage="Código do cliente"></qm-label>
            </div>
            <div class="col-3">
                <qm-input (inputEvent)="changeCommand($event)"
                          (blurEvent)="updateCommand($event)"
                          [hideLabel]="true" [(ngModel)]="clientCode" [disabled]="chatMode.readOnlyCode"></qm-input>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="dataMode">
          <div class="grid">
            <div class="flex align-items-center justify-content-start ml-2 p-2">
                <input id="data_check" [(ngModel)]="dataMode" type="checkbox" class="mr-2" />
                <qm-label attachComponent="data_check" [labelMessage]="chatMode.dataCodeLabel" styleFont="bold"
                [styleColor]="darkMode? 'white': 'black'" sizeFont="normal"></qm-label>
            </div>
          </div>
        </ng-container>

        <div class="grid">
          <div class="col">
            <qm-input (keyup.enter)="sendMessage()" [hideLabel]="true" [(ngModel)]="chatInput"></qm-input>
          </div>
          <div style="width: 50px" class="col-fixed flex align-items-center justify-content-center mt-1">
            <qm-button [buttonType]="chatStyle" [buttonIconAction]="processing? 'waiting': 'send'" *ngIf="canShowSendButton()"
            [onlyIcon]="true" (clickEvent)="sendMessage();" [disabled]="invalid || processing" ></qm-button>
            <qm-button [buttonType]="chatStyle" [buttonIconAction]="processing? 'waiting': 'record'" *ngIf="canShowStartRecordButton()"
            [onlyIcon]="true" (clickEvent)="startRecording();" [disabled]="invalid || processing" ></qm-button>
            <qm-button [buttonType]="chatStyle" buttonIconAction="pause" [onlyIcon]="true" *ngIf="canShowPauseRecordButton()"
            (clickEvent)="stopRecording();" [disabled]="invalid || processing" ></qm-button>
          </div>
        </div>

      </div>

    </div>

    <div class="dashboard-height" *ngIf="this.dashboardView">
      <powerbi-report [embedConfig]="this.powerbiConfig" cssClassName="pbi-report"></powerbi-report>
    </div>

    <div class="flex justify-content-center align-items-center m-2 m-9" *ngIf="this.dashboardView">
      <qm-button (click)="returnGenerativeMode()" labelMessage="Retornar ao modo generativo" buttonIconAction="none"></qm-button>
    </div>

  `,
  styleUrls: ['./qm-chat.component.css']
})
export class QmChatComponent implements OnInit, OnDestroy {

  _clientCode!: string;
  chatInput: string = "";
  messageList!: MessageData[];
  processing: boolean = false;
  invalid: boolean = false;
  isRecording: boolean = false;
  dashboardView: boolean = false;
  powerbiConfig!: IReportEmbedConfiguration;

  @Input()
  chatStyle: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'help' | 'error' = 'primary';

  @Input()
  darkMode: boolean = false;

  @Input()
  hideHeader: boolean = false;

  @Input()
  backgroundColorChat: string = "";

  @Input()
  messageUser!: ChatUserData;

  @Input()
  chatMode!: ChatMode;

  @Input()
  dataMode: boolean = false;

  @Output()
  messageSentEvent = new EventEmitter<ChatEventData>();

  get clientCode(): string {
      return this._clientCode;
  }

  @Input() set clientCode(value: string) {
     if (value) {
      this._clientCode = value;
      this.invalid = this._clientCode.length === 0;
     }
  }

  get customStyleSheet(): string {
    return this.customStyleSheet;
  }

  @Input() set customStyleSheet(value: string) {
      this.addStyle(value);
  }

  @ViewChild("scrollbarRef") scrollbar!: NgScrollbar;

  private readonly scrollBarClass;
  private readonly MIN_WAIT  = 250;

  constructor(private chatService: ChatService, 
              private chatRecordingService: AudioRecordingService,
              private chatPBIService: ChatPowerBiService,
              @Inject(DOCUMENT) private document: Document) {

    this.scrollBarClass = new Map<string, string>([
      ['primary', 'chatScrollBar--primary'],
      ['secondary', 'chatScrollBar--secondary'],
      ['success','chatScrollBar--success'],
      ['info','chatScrollBar--info'],
      ['warning','chatScrollBar--warning'],
      ['help','chatScrollBar--help'],
      ['error','chatScrollBar--error']
    ])

  }

  ngOnInit(): void { 
    this.messageList = [];
    this.chatService.createdMessage$().pipe(
      filter(msg => msg !== undefined),
      tap(() => this.processing = true)
    ).subscribe((newMsg) => this.messageList.push(newMsg!));

    this.chatService.updatedMessage$()
        .pipe(
          filter(msg => msg !== undefined),
          concatMap(msg => of({messageValue: this.messageList.find(x => x.id === msg!.id), messageSent: msg})),
          concatMap(cv1 => (cv1.messageSent!.typing ? this.typingEffect(cv1.messageValue, cv1.messageSent) : this.assignmentEffect(cv1.messageValue, cv1.messageSent)).pipe(
            map(curr => ({messageValue: cv1.messageValue, messageSent: cv1.messageSent, currentValue: curr})),
            this.finalizeWithValue(cv1 => this.assignMessageStatus(cv1.messageValue, cv1.messageSent))
          ))
        )
        .subscribe();

      this.chatService.commandMessage$().pipe(
        filter(msg => msg !== undefined),
      ).subscribe((newMsg) => this.messageList.push(newMsg!));

    this.chatRecordingService
      .recordingFailed()
      .subscribe(() => (this.isRecording = false));

    this.chatRecordingService.getRecordedBlob().subscribe(data => {
      let contentpath = URL.createObjectURL(data.blob);
      let mediaMsg: MessageMediaContent = {media: data.blob, mediapath: contentpath};
      let msgIdReply = this.chatService.createMediaMessage(mediaMsg);
      this.messageSentEvent.emit({id: msgIdReply, content: "", mediacontent: mediaMsg, clientCode: this.clientCode, dataMode: this.dataMode});
    });

  }

  ngOnDestroy(): void {
    this.chatService.createdMessage$().unsubscribe();
    this.chatService.updatedMessage$().unsubscribe();
    this.chatService.commandMessage$().unsubscribe();
    this.abortRecording();
    this.messageList.forEach(el => {
      if (el.mediacontent) {
         URL.revokeObjectURL(el.mediacontent.mediapath);
      }
    });
  }


  finalizeWithValue<T>(callback: (value: T) => void) {
    return (source: Observable<T>) => defer(() => {
      let lastValue: T;
      return source.pipe(
        tap(value => lastValue = value),
        finalize(() => callback(lastValue)),
      )
    })
  }

  typingEffect(currentMessage: MessageData | undefined, updatedMessage: MessageUpdate | undefined): Observable<string> {
    this.checkEraseMessage(currentMessage);
    return interval(5).pipe(
      map(x => currentMessage!.content += updatedMessage!.content[x]),
      take(updatedMessage!.content.length)
    ).pipe(delay(this.MIN_WAIT))
  }

  assignmentEffect(currentMessage: MessageData | undefined, updatedMessage: MessageUpdate | undefined): Observable<string> {
      let delayStage = this.MIN_WAIT * 2;
      this.checkEraseMessage(currentMessage);
      switch(updatedMessage!.state) {
        // @ts-ignore
        case 'erase':
            delayStage = this.MIN_WAIT * 4;
        case 'error':
            return of(currentMessage!.content = updatedMessage!.content).pipe(delay(delayStage));
        default: 
            return of(currentMessage!.content += updatedMessage!.content).pipe(delay(delayStage));
      }
  }

  assignMessageStatus(currentMessage: MessageData | undefined, updatedMessage: MessageUpdate | undefined) {
      currentMessage!.state = updatedMessage!.state;
      currentMessage!.options = updatedMessage!.options;
      currentMessage!.mediacontent = updatedMessage!.mediacontent;
      currentMessage!.datetime = new Date();
    
      if (currentMessage!.state === 'error' || currentMessage!.state === 'finish') {
          this.processing = false;
      }
      this.scrollChat();
  }

  checkEraseMessage(currentMessage: MessageData | undefined) {
    if (currentMessage!.state === "erase") {
      currentMessage!.content = "";
    }
  }

  sendMessage() {
    if (this.chatInput !== "" && !this.processing && !this.invalid) {

      if (this.chatMode.ragApi && this.chatInput.startsWith(this.chatMode.commandCode)) {
        this.clientCode =  this.chatInput.substring(this.chatMode.commandCode.length);
        this.updateCommand(true);
      } else if (this.chatInput === this.chatMode.dataCode) {
          this.dataMode = true;
      } else if (this.chatInput === this.chatMode.reportConfig.reportCode) {
        this.chatPBIService.requestReportData(this.chatMode.reportConfig.config).subscribe((config: PowerBiComponent) => {
            this.powerbiConfig = {
                id: config.reportId, 
                type: 'report', 
                embedUrl: config.embedUrl,
                accessToken: config.embedToken,
                tokenType: models.TokenType.Embed,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: true
                    }
                  }
                }
              }
              this.dashboardView = true;
          });
      } else {
        let msgIdReply = this.chatService.createSenderMessage(this.chatInput);
        this.messageSentEvent.emit({id: msgIdReply, content: this.chatInput, clientCode: this.clientCode, dataMode: this.dataMode});
      }

      this.chatInput = "";
      this.scrollChat();
    }
  }

  changeCommand(event: any) {
      if (this.isRecording) {
        this.abortRecording();
      }
      this.invalid = event.target.value.length === 0;
  }

  updateCommand(updatedValue: boolean) {
      if (this.clientCode !== "" && updatedValue) {
          this.chatService.createCommandMessage(LOAD_PLACEHOLDER + this.clientCode.trim())
      }
  }

  returnGenerativeMode() {
    this.dashboardView = false;
  }

  scrollChat() {
    setTimeout(() => this.scrollbar.scrollTo({ bottom: 0 }));
  }

  public scrollBarResolver(): string {
    return this.scrollBarClass.get(this.chatStyle) || 'chatScrollBar--primary';
  }

  addStyle(styleString: string) {
    if (styleString) {
      const style = this.document.createElement('style');
      style.textContent = styleString;
      this.document.head.append(style);
    }
  }

  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this.chatRecordingService.startRecording();
    }
  }

  private abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this.chatRecordingService.abortRecording();
    }
  }

  stopRecording() {
    if (this.isRecording) {
      this.chatRecordingService.stopRecording();
      this.isRecording = false;
    }
  }

  canShowSendButton() {
    return this.chatMode.modeType === 'TEXT_ONLY' || this.chatInput !== "";
  }

  canShowStartRecordButton() {
    return this.chatInput === "" && !this.isRecording && this.chatMode.modeType === 'TEXT_MEDIA';
  }

  canShowPauseRecordButton() {
    return this.isRecording && this.chatMode.modeType === 'TEXT_MEDIA';
  }

}

