import { Component, EventEmitter, forwardRef, Inject, Injector, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { HSLA, HSVA, RGBA } from 'ngx-color';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';
@Component({
  selector: 'qm-colorpicker',
  template: `
    <qm-input-title [titleMessage]="titleMessage" [optionalField]="!this.required"></qm-input-title>
    <div class="swatch" (click)="handleClick()" [pTooltip]="controlValue.toString()">
      <div class="color" [ngStyle]="{'background': controlValue}"></div>
    </div>
      <div *ngIf="displayColorPicker" class="popover">
      <div class="cover" (click)="handleClose()"></div>
        <color-sketch
        [(color)]="controlValue"
        (onChange)="onChangeValue($event)"
        [presetColors]="[]"></color-sketch>
      </div>
     <qm-input-legend [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>
  `,
  styleUrls: ['./qm-colorpicker.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmColorpickerComponent),
        multi: true
    }
]
})
export class QmColorpickerComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue: HSLA | HSVA | RGBA | string = "#000000";
  onChanged = (_val: any) => {};
  onTouched = () => {}
  displayColorPicker: boolean = false;

  @Output()
  changeEvent = new EventEmitter<any>();

  handleClick() {
    if (this.disabled) {
      this.displayColorPicker = false;
    } else {
      this.displayColorPicker = !this.displayColorPicker;
    }
  }

  handleClose() {
    this.displayColorPicker = false;
  }

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.setupLegendMessage();
  }

  writeValue(val: string): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.onChanged(event.color.hex);
    this.changeEvent.emit(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
