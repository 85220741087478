import { Component, Input } from '@angular/core';

@Component({
  selector: 'qm-toast',
  template: `<p-toast [key]="idToast" position="top-right"></p-toast>`,
  styleUrls: ['./qm-toast.component.css']
})
export class QmToastComponent {

  @Input()
  idToast!: string;

}
