import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'qm-split-button[labelMessage][menuItems]',
  template: `
      <p-splitButton
      [label]="labelMessage"
      [icon]="buttonIconResolver()"
      (click)="clickEvent.emit($event)"
      [styleClass]="buttonClassResolver()"
      [model]="menuItems">
    </p-splitButton>
  `,
  styleUrls: ['./qm-split-button.component.css']
})
export class QmSplitButtonComponent{

  /**
  * Define a cor do botão
  */
  @Input()
  buttonType: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'error' = 'primary';

  /**
  * Define o ícone do botão
  */
   @Input()
   buttonIconAction: 'create' | 'save' | 'edit' | 'view' | 'delete' | 'export' | 'yes' | 'no' | 'logout' | 'none' = 'none';

  /**
  * Define o rótulo do botão
  */
  @Input()
  labelMessage: string = "";

  @Output()
  clickEvent = new EventEmitter<Event>();

  @Input()
  menuItems!:  MenuItem[];

  private readonly typeActions;
  private readonly iconActions;

  constructor() {

    this.typeActions = new Map<string, string>([
      ['primary', ''],
      ['secondary', 'p-button-secondary'],
      ['success','p-button-success'],
      ['info','p-button-info'],
      ['warning','p-button-warning'],
      ['error','p-button-danger']
    ])

    this.iconActions = new Map<string, string>([
      ['create', 'pi pi-plus'],
      ['save', 'pi pi-save'],
      ['edit', 'pi pi-pencil'],
      ['view', 'pi pi-eye'],
      ['delete','pi pi-trash'],
      ['export','pi pi-chart-line'],
      ['yes', 'pi pi-check'],
      ['no', 'pi pi-times'],
      ['logout', 'pi pi-sign-out']
    ])

  }

  public buttonIconResolver(): string {
    return this.iconActions.get(this.buttonIconAction) || '';
  }

  public buttonClassResolver():string {
    return this.typeActions.get(this.buttonType) || '';
  }

}
