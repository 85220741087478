import { Component, Input} from '@angular/core';

@Component({
  selector: 'qm-label[labelMessage]',
  template: `
  <label
  [for]="attachComponent"
  [ngStyle]="{'font-weight': styleClassResolver(), 'color': colorStyleResolver(), 'line-height': lineHeight }"
  [ngClass]="labelClassResolver()">{{labelMessageData}}</label> `,
  styleUrls: ['./qm-label.component.css']
})
export class QmLabelComponent {

  @Input()
  labelMessage: string | null = "";

  @Input()
  sizeFont: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'normal' | 'small' | 'legend' | 'dialog-title' = 'normal';

  @Input()
  styleFont: 'normal' | 'bold' | 'thin' | 'light' | 'black' | 'medium' = 'normal';

  @Input()
  styleColor: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'help' | 'error' | 'plain' | 'legend' | 'title' | 'special' | 'black' | 'white' =  'plain';

  @Input()
  lineHeight: string = "100%";

  @Input()
  attachComponent!: string;

  @Input()
  errorInput = false;

  @Input()
  addColon = false;

  private readonly sizeClasses;
  private readonly styleClasses;
  private readonly styleColorList;

  constructor() {

    this.sizeClasses = new Map<string, string>([
      ['h1', 'label--h1'],
      ['h2', 'label--h2'],
      ['h3','label--h3'],
      ['h4','label--h4'],
      ['h5','label--h5'],
      ['h6','label--h6'],
      ['normal','label--normal'],
      ['small','label--small'],
      ['legend','label--legend'],
      ['dialog-title','label--dialogtitle']
    ])

    this.styleColorList = new Map<string, string>([
      ['primary', 'var(--system-primary)'],
      ['secondary', 'var(--system-secondary)'],
      ['success','var(--system-success)'],
      ['info','var(--system-info)'],
      ['warning','var(--system-warning)'],
      ['error','var(--system-error)'],
      ['plain','var(--system-black)'],
      ['legend','var(--system-legend)'],
      ['title','var(--system-title)'],
      ['special','var(--system-special)'],
      ['black','var(--system-black)'],
      ['white','var(--system-white)'],
    ])

    this.styleClasses = new Map<string, string>([
      ['normal', 'normal'],
      ['bold', 'bold'],
      ['medium', '500'],
      ['light','300'],
      ['thin','100'],
      ['black','900']
    ])

  }

  get labelMessageData() {
    return this.addColon? this.labelMessage + ":" : this.labelMessage;
  }

  public labelClassResolver(): string[] {
    return [this.sizeClasses.get(this.sizeFont) || ''];
  }

  public styleClassResolver(): string[] {
    return [this.styleClasses.get(this.styleFont) || ''];
  }

  public colorStyleResolver(): string {

    if (this.errorInput) {
        return this.styleColorList.get('error') || '';
    } else {
        return this.styleColorList.get(this.styleColor) || '';
    }

  }


}
