import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'qm-dialog[headerTitle]',
  template: `
    <p-dialog [(visible)]="displayDialog" [style]="{width: dialogWidth, height: dialogHeight}"
              position="center"
              [draggable]="false" [resizable]="false" [modal]="true"
              (onShow)="showEvent.emit($event)" (onHide)="hideEvent.emit($event)">
              <ng-template pTemplate="header">
                <qm-label sizeFont="h3" styleFont="bold" [labelMessage]="headerTitle" styleColor="plain"></qm-label>
            </ng-template>
          <ng-content></ng-content>
    </p-dialog>
  `,
  styleUrls: ['./qm-dialog.component.css']
})
export class QmDialogComponent {

  @Input()
  headerTitle: string = "";

  @Input()
  displayDialog: boolean = false;

  @Input()
  dialogWidth: string = "50vw";

  @Input()
  dialogHeight: string = "25vw";

  @Output()
  showEvent = new EventEmitter<any>();

  @Output()
  hideEvent = new EventEmitter<any>();


}
