import { Component } from '@angular/core';

@Component({
  selector: 'qm-pagesystemerror',
  template: `
  <div class="grid" style="margin: 5rem">
      <div class="col-9">
        <div class="flex flex-column align-items-start gap-5">
            <qm-label [labelMessage]="error" sizeFont="h5" styleFont="medium" styleColor="special"></qm-label>
            <qm-label [labelMessage]="title" sizeFont="h1" styleFont="medium" styleColor="special"></qm-label>
            <qm-label [labelMessage]="detail" sizeFont="h3" styleColor="special" lineHeight="160%"></qm-label>
            <qm-button [labelMessage]="btnHome" routerLink="/home"></qm-button>
        </div>
    </div>
      <div class="col-3">
        <img src="assets/img/500.png">
      </div>
    </div>
  `,
  styleUrls: ['./pagesystemerror.component.css']
})
export class PagesystemerrorComponent {

  public title: string = "Erro interno de servidor"
  public error: string = "erro 500"
  public btnHome: string = "Ir para página inicial"
  public detail: string = `Desculpe, aconteceu um problema, mas já estamos trabalhando pra resolver.
                            Tente novamente mais tarde`


}
