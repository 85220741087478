import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'qm-breadcrumb[model]',
  template: `
    <p-breadcrumb [model]="model"></p-breadcrumb>
  `,
  styleUrls: ['./qm-breadcrumb.component.css']
})
export class QmBreadcrumbComponent {

  @Input()
  model!: MenuItem[]

}
