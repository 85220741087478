import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor{

  constructor( public loaderService : LoaderService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!req.url.includes('api/v3/chat_ai') && !req.url.includes('api/v3/pbi')){
      this.loaderService.show();
    }
    return next.handle(req).pipe( finalize(() => setTimeout(() => {this.loaderService.hide()}, 500)));
  }
}
