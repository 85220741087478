import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';

@Component({
  selector: 'qm-input-textarea[titleMessage]',
  template: `
  <qm-input-title [titleMessage]="titleMessage" [optionalField]="!this.required"></qm-input-title>
  <textarea
  pInputTextarea
  [ngModel]="controlValue"
  (ngModelChange)="onModelChange($event)"
  (blur)="onTouched()"
  (input)="onChangeValue($event)"
  [disabled]="disabled"
  [ngClass]="{'ng-dirty ng-invalid': invalidInput}"
  [autoResize]="autoResize"
  [maxlength]="maxSize"
  pInputText class="w-full mt-2 mb-1"
  rows="4"></textarea>
  <qm-input-legend [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>
  `,
  styleUrls: ['./qm-input-textarea.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmInputTextareaComponent),
        multi: true
    }
]
})
export class QmInputTextareaComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue!: string | undefined;
  onChanged = (_val: string) => {};
  onTouched = () => {}

  @Input()
  autoResize = false;

  @Input()
  maxSize: number = 4000;

  @Output()
  inputEvent = new EventEmitter<any>();

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.setupLegendMessage();
  }

  writeValue(val: string): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(event: any) {
    this.onChanged(event);
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.inputEvent.emit(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }


}
