import { Component, Input } from '@angular/core';

@Component({
  selector: 'qm-confirmdialog[titleMessage]',
  template: `

  <p-confirmDialog #cd [style]="{width: dialogWidth, height: dialogHeight}" [key]="idDialog" icon="pi pi-exclamation-triangle">
    <ng-template pTemplate="header">
        <qm-label [labelMessage]="titleMessage" sizeFont="dialog-title" [errorInput]="true"></qm-label>
        <qm-button buttonStyle="text" buttonIconAction="no" [ignoreColor]="true" [rounded]="true" (clickEvent)="cd.hide()"></qm-button>
    </ng-template>
    <ng-template pTemplate="footer">
        <qm-button buttonStyle="text" buttonType="error" buttonIconAction="no" [labelMessage]="noButtonLabel" (clickEvent)="cd.reject()"></qm-button>
        <qm-button buttonStyle="text" buttonType="success" buttonIconAction="yes" [labelMessage]="yesButtonLabel" (clickEvent)="cd.accept()"></qm-button>
    </ng-template>
</p-confirmDialog>

  `,
  styleUrls: ['./qm-confirmdialog.component.css']
})
export class QmConfirmDialogComponent {

  @Input()
  idDialog!: string;

  @Input()
  dialogWidth: string = "35vw";

  @Input()
  dialogHeight: string = "12vw";

  @Input()
  titleMessage: string = "";

  @Input()
  yesButtonLabel: string = "";

  @Input()
  noButtonLabel: string = "";

}
