import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';

@Component({
  selector: 'qm-input',
  template: `
  <qm-input-title *ngIf="!hideLabel" [titleMessage]="titleMessage" [optionalField]="!this.required"></qm-input-title>
  <input pInputText
   [(ngModel)]="controlValue"
   [disabled]="disabled"
   [attr.id]="clientId"
   [placeholder]="placeholderMessage"
   (blur)="onBlurEvent($event)"
   (input)="onChangeValue($event)"
   [ngClass]="{'ng-dirty ng-invalid': invalidInput}"
   [minlength]="minSize"
   [maxlength]="maxSize"
   class="w-full mt-2 mb-1">
   <qm-input-legend *ngIf="!hideLabel" [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>
  `,
  styleUrls: ['./qm-input.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmInputComponent),
        multi: true
    }
]
})
export class QmInputComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue!: string | undefined;
  updated: boolean = false;
  onChanged: Function = (_val: string) => {};
  onTouched: Function = () => {}

  @Input()
  clientId!: string;

  @Input()
  minSize: number = 0;

  @Input()
  maxSize: number = 255;

  @Input()
  hideLabel: boolean = false;

  @Output()
  inputEvent = new EventEmitter<any>();

  @Output()
  blurEvent = new EventEmitter<boolean>();

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.setupLegendMessage();
  }

  writeValue(val: string): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onBlurEvent(_event: any) {
      this.onTouched();
      this.blurEvent.emit(this.updated);
      this.updated = false;
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.onChanged(event.target.value);
    this.inputEvent.emit(event);
    this.updated = true;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
