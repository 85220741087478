import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';

@Component({
  selector: 'qm-input-mask[titleMessage][maskType]',
  template: `
  <qm-input-title [titleMessage]="titleMessage" [optionalField]="!this.required"></qm-input-title>
  <p-inputMask
   [ngModel]="controlValue"
   (ngModelChange)="onModelChange($event)"
   [mask]="maskTypeResolver()"
   [unmask]="unmaskValue"
   (onInput)="onChangeValue($event)"
   (onBlur)="onTouched()"
   [disabled]="disabled"
   [ngClass]="checkError"
   [placeholder]="placeHolderTypeResolver()"
  styleClass="w-full mt-2 mb-1"></p-inputMask>
  <qm-input-legend [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>
  `,
  styleUrls: ['./qm-input-mask.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmInputMaskComponent),
        multi: true
    }
  ]
})
export class QmInputMaskComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

    controlValue!: string | undefined;
    onChanged = (_val: string) => {};
    onTouched = () => {}

    @Input()
    maskType: 'CPF' | 'CNPJ' | 'TEL' | 'none' = 'none';

    @Input()
    unmaskValue = false;

    @Output()
    inputEvent = new EventEmitter<any>();

    private readonly maskTypeValues;

    constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
      super();

      this.maskTypeValues = new Map<string, string>([
        ['CPF', '999.999.999-99'],
        ['CNPJ', '99.999.999/9999-99'],
        ['TEL', '(99) 9999-9999']
      ]);

    }

    public maskTypeResolver(): string {
      return this.maskTypeValues.get(this.maskType) || '';
    }

    public placeHolderTypeResolver(): string {
      return this.maskTypeValues.get(this.maskType)?.replace(/9/g, '_') || '';
    }


  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.controlForm.addValidators(QmInputMaskComponent.dataValidator(this.maskType))
    this.controlForm.updateValueAndValidity({onlySelf: true})
    this.setupLegendMessage();
  }

  get checkError() {
    return {
      'ng-invalid ng-dirty': (this.invalidInput) ||
                             (this.invalidInput && this.controlForm.errors?.['cpfInvalid']) ||
                             (this.invalidInput && this.controlForm.errors?.['cnpjInvalid'])
    }
  }

  writeValue(val: string): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(event: any) {
    this.onChanged(event);
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.inputEvent.emit(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

 static isValidCPF(value: string) {

  value = value.replace(/[^\d]+/g, '');

  if (value.length !== 11 || !Array.from(value).filter(e => e !== value[0]).length) {
    return false
  }

  if (!!value.match(/(\d)\1{10}/)) {
    return false;
  }

  const values = value.split('').map(el => +el);
  const rest = (count: number) => (values.slice(0, count-12).reduce( (soma, el, index) => (soma + el * (count-index)), 0 )*10) % 11 % 10;

  return rest(10) === values[9] && rest(11) === values[10];
}

 static isValidCNPJ(cnpj: string): boolean {

  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14 || !Array.from(cnpj).filter(e => e !== cnpj[0]).length ) {
    return false;
  }

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != parseInt(digitos.charAt(0))) {
    return false;
  }

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != parseInt(digitos.charAt(1))) {
    return false;
  }

  return true;
}

static dataValidator(maskType: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (maskType === 'CPF' && control.value !== "" && !QmInputMaskComponent.isValidCPF(control.value)) {
      return { 'cpfInvalid' : true };
  }

  if (maskType === 'CNPJ' && control.value !== "" && !QmInputMaskComponent.isValidCNPJ(control.value)) {
     return { 'cnpjInvalid' : true };
  }

    return null;

  }
}

}
