<ng-container *transloco="let t">

  <div class="grid pb-2">

    <div class="col-2 flex justify-content-center align-items-center">
      <a routerLink="/home">
          <img src="assets/img/logo-claro.png" height="55" class="mr-3 ml-2">
      </a>
    </div>

    <div class="col-2 flex justify-content-center align-items-center">
      <p-menubar class="qm-menubar" [model]="items">
      </p-menubar>
    </div>

    <div class="col-5 flex justify-content-center align-items-center">
      <qm-autocomplete [inputSuggestions]="listaSuggestions" [ignoreOptional]=true
      (searchEvent)="onSearchData($event)" style="width: 100%"
      (selectEvent)="onSelectData($event)" [(ngModel)]="moduleValue"
      placeholderMessage="{{ t('header-search-placeholder') }}"></qm-autocomplete>
    </div>

    <div class="col-2 flex justify-content-center align-items-center">
      <qm-label labelMessage="{{ t('header-version') }} 1.8" sizeFont="small"></qm-label>
    </div>

    <div class="col-1 flex justify-content-center align-items-center">
        <qm-button (clickEvent)="logout()" labelMessage="{{ t('header-logout') }}" buttonIconAction="logout"></qm-button>
    </div>

  </div>

</ng-container>
