import { Component, Input } from '@angular/core';

@Component({
  selector: 'qm-card[titleMessage]',
  template: `
    <p-card styleClass="mb-5">
      <div class="flex align-items-center justify-content-between mb-2">
        <qm-label sizeFont="h2" styleFont="bold" [labelMessage]="titleMessage" styleColor="plain"></qm-label>
        <ng-content select="[headerButton]"></ng-content>
      </div>
      <div class="border-title flex mb-4"> </div>
      <ng-content></ng-content>
    </p-card>
  `,
  styleUrls: ['./qm-card.component.css']
})
export class QmCardComponent {

  @Input()
  titleMessage!: string;

}
