import { Component } from '@angular/core';

@Component({
  selector: 'qm-pageforbidden',
  template: `
    <div class="grid" style="margin: 5rem">
      <div class="col-9">
        <div class="flex flex-column align-items-start gap-5">
            <qm-label [labelMessage]="error" sizeFont="h5" styleFont="medium" styleColor="special"></qm-label>
            <qm-label [labelMessage]="title" sizeFont="h1" styleFont="medium" styleColor="special"></qm-label>
            <qm-label [labelMessage]="detail" sizeFont="h3" styleColor="special" lineHeight="160%"></qm-label>
            <qm-button [labelMessage]="btnHome" routerLink="/home"></qm-button>
        </div>
    </div>
      <div class="col-3">
        <img src="assets/img/403.png">
      </div>
    </div>
  `,
  styleUrls: ['./pageforbidden.component.css']
})
export class PageforbiddenComponent {

  public title: string = "Acesso não permitido"
  public error: string = "erro 403"
  public btnHome: string = "Ir para página inicial"
  public detail: string = `A página que você está tentando acessar possui acesso restrito.
                          Consulte o administrador do sistema`

}
