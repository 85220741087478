import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { PagenotfoundComponent } from './template/components/pagenotfound/pagenotfound.component';
import { PageforbiddenComponent } from './template/components/pageforbidden/pageforbidden.component';
import { PagesystemerrorComponent } from './template/components/pagesystemerror/pagesystemerror.component';
import { AuthCallbackComponent } from './auth/auth-callback.component';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', canActivate: [AuthGuard], component: HomeComponent },
  {
    path: 'users', canActivate: [AuthGuard],
    loadChildren: () => import('./users/user.module').then(m => m.UsersModule)
  },
  { path: 'survey', canActivate: [AuthGuard], 
   loadChildren: () => import('./survey/survey.module').then(m => m.SurveyModule) 
  },
  { path: 'setup', canActivate: [AuthGuard], 
   loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule) 
  },
  { path: 'model', canActivate: [AuthGuard],
    loadChildren: () => import('./model/model.module').then(m => m.ModelModule)
  },
  { path: 'ldapConfig', canActivate: [AuthGuard], 
   loadChildren: () => import('./ldap/ldapconfig.module').then(m => m.LdapConfigModule) 
  },
  {
    path: 'tipoRegraValidacao', canActivate: [AuthGuard],
    loadChildren: () => import('./regraValidacao/tipoRegraValidacao/tipo-regra-validacao.module').then(m => m.TipoRegraValidacaoModule)
  },
  {
    path: 'regraValidacao', canActivate: [AuthGuard],
    loadChildren: () => import('./regraValidacao/regra-validacao.module').then(m => m.RegraValidacaoModule)
  },
  {
    path: 'associacaoRegraUnidadeSistema', canActivate: [AuthGuard],
    loadChildren: () => import('./regraValidacao/associacaoRegraUnidadeSistema/associacao-regra-unidade-sistema.module').then(m => m.AssociacaoRegraUnidadeSistemaModule)
  },
  {
    path: 'relatorio', canActivate: [AuthGuard],
    loadChildren: () => import('./regraValidacao/relatorio/relatorio.module').then(m => m.RelatorioModule)
  },
  {
    path: 'subfaturamento', canActivate: [AuthGuard],
    loadChildren: () => import('./subfaturamento/subfaturamento.module').then(m => m.SubFaturamentoModule)
  },
  {
    path: 'assunto', canActivate: [AuthGuard],
    loadChildren: () => import('./subfaturamento/assunto/assunto.module').then(m => m.AssuntoModule)
  },
  {
    path: 'subfaturamento-fluxograma', canActivate: [AuthGuard],
    loadChildren: () => import('./subfaturamento/fluxograma/fluxograma.module').then(m => m.FluxogramaModule)
  },
  {
    path: 'fluxograma', canActivate: [AuthGuard],
    loadChildren: () => import('./regraValidacao/fluxograma/fluxograma.module').then(m => m.FluxogramaModule)
  },
  { path: 'auth', component: AuthCallbackComponent},
  { path: 'unauthorized',  component: PageforbiddenComponent },
  { path: '403',  component: PageforbiddenComponent },
  { path: '404',  component: PagenotfoundComponent },
  { path: '500',  component: PagesystemerrorComponent },
  { path: '**',  component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
