import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TreeNodeCustom } from './qm-tree.type';

@Component({
  selector: 'qm-tree[nodeList]',
  template: `
    <p-tree [value]="nodeList" [filter]="filterEnabled"
            [(selection)]="selectionValue"
            [selectionMode]="selectionType === 'none' ? '': selectionType"
            (onNodeExpand)="onNodeExpandEvent($event)"
            (onNodeCollapse)="onNodeCollapseEvent($event)" 
            (onNodeSelect)="onNodeSelectEvent($event)"
            (onNodeUnselect)="onNodeUnselectEvent($event)"
    >
      <ng-template let-node pTemplate="default">
          <div class="flex gap-2">
              <qm-icon [styleIcon]="node.iconType" sizeFont="normal" [styleColor]="node.iconColor"></qm-icon>
              <qm-label [pTooltip]="node.tooltipValue"
              [labelMessage]="node.label" [styleColor]="node.messageColor" 
              [styleFont]="node.fontType"></qm-label>
          </div>
      </ng-template>
  
  </p-tree>
  `,
  styleUrls: ['./qm-tree.component.css'],
})
export class QmTreeComponent { 

  selectionValue: TreeNode[];
  _nodeList: TreeNode[];

  constructor() {
    this.selectionValue = [];
    this._nodeList = [];
  }

  @Input()    
  filterEnabled: boolean = false; 

  @Input()    
  selectionType: "none" | "single" | "checkbox" = "none";

  @Output()
  nodeExpandEvent = new EventEmitter<any>();

  @Output()
  nodeCollapseEvent = new EventEmitter<any>();

  @Output()
  nodeSelectEvent = new EventEmitter<any>();
  
  @Output()
  nodeUnselectEvent = new EventEmitter<any>();

  get nodeList(): TreeNode[] {
    return this._nodeList;
  }

  @Input() set nodeList(values: TreeNodeCustom[]) {
      this._nodeList = values;
    }

  onNodeExpandEvent(event: any) {
    this.nodeExpandEvent.emit(event.node);
  }

  onNodeCollapseEvent(event: any) {
    this.nodeCollapseEvent.emit(event.node);
  }

  onNodeSelectEvent(event: any) {
    this.nodeSelectEvent.emit(event.node);
  }

  onNodeUnselectEvent(event: any) {
    this.nodeUnselectEvent.emit(event.node);
  }

}
