import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { QmAutocompleteData } from 'src/uikit/qm-autocomplete/qm-autocomplete-data';
import { ChatUserData } from 'src/uikit/qm-chat/chat-user.interface';
import { QmCheckboxData } from 'src/uikit/qm-checkbox-group/qm-checkbox-data';
import { QmOrderlistData } from 'src/uikit/qm-orderlist/qm-orderlist-data';
import { QmPicklistData } from 'src/uikit/qm-picklist/qm-picklist-data';
import { QmRadiobuttonData } from 'src/uikit/qm-radiobutton-group/qm-radiobutton-data';
import { QmSelectData } from 'src/uikit/qm-select/qm-select-data';
import { QMTableData } from 'src/uikit/qm-table/qm-table-data.interface';
import { QMTableOptions } from 'src/uikit/qm-table/qm-table-options.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [ConfirmationService, MessageService]
})
export class HomeComponent implements OnInit {

  listaPessoas!: QmSelectData[];
  listaOptions!: QmRadiobuttonData[];
  listaCheckOptions!: QmCheckboxData[];
  listaAutoComplete!: QmAutocompleteData[];
  listaSuggestions: QmAutocompleteData[] = [];
  claims: any;

  tableData!: QMTableData<any>;
  tableData2!: QMTableData<any>;
  tableOptions! : QMTableOptions;

  sourceList!:  QmPicklistData[];
  targetList:  QmPicklistData[] = [];
  sourceOrderList!: QmOrderlistData[];

  myText: string = "";
  showDialog: boolean = false;

  access_token!: string;
  refresh_token!: string;
  id_token!: string;

  messageUser: ChatUserData = {
    username: "Chat GPT",
    avatar: "/assets/img/chat-gpt.png",
    online: true
  }

  constructor(private confirmService: ConfirmationService,
              private messageService: MessageService) {
    

  }

  ngOnInit(): void {
    
  }
  
  
    onChangeValue(event: any) {
      console.log(event);
    }

    showDialogEvent() {
      this.showDialog = true;
    }

    hideDialogEvent() {
      this.showDialog = false;
    }

  onSearchData(event: any): void {
    this.listaSuggestions = this.listaAutoComplete.filter(el => el.label?.trim().toLowerCase().indexOf(event.query?.trim().toLowerCase()) !== -1);
    }

  confirm() {
    this.confirmService.confirm({
      key: 'qm-dialog',
      message: "Deseja sair? Informações que não foram salvas serão perdidas",
      accept: () => {
        console.log('Você aceitou');
      },
      reject: () => {
        console.log('Você rejeitou');
      }
    })
  }

  confirmPopup(event: Event) {
    this.confirmService.confirm({
      key: 'qm-popup',
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      message: "Deseja apagar os registros? Esta operação é irreversível",
      accept: () => {
        console.log('Você aceitou');
      },
      reject: () => {
        console.log('Você rejeitou');
      }
    })
  }

  confirmSucesso() {
    this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService', sticky: true});
  }

  confirmInfo() {
    this.messageService.add({severity:'info', summary:'Service Message', detail:'Via MessageService', sticky: true});
  }

  confirmAviso() {
    this.messageService.add({severity:'warn', summary:'Service Message', detail:'Via MessageService', sticky: true});
  }

  confirmFalha() {
    this.messageService.add({severity:'error', summary:'Service Message', detail:'Via MessageService', sticky: true});
  }


}




