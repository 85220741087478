import { Component, Input} from '@angular/core';
import { PrimeIcons } from 'primeng/api';
import { IconColorTypes, IconTypes } from './qm-icon-types.interface';

@Component({
  selector: 'qm-icon[styleIcon]',
  template: `
  <em
  [ngStyle]="{'font-size': labelClassResolver(), 'color': colorStyleResolver()}"
  [class]="iconClassResolver()"></em> `,
  styleUrls: ['./qm-icon.component.css']
})
export class QmIconComponent {

  @Input()
  sizeFont: 'normal' | 'small' | 'legend' = 'normal';

  @Input()
  styleColor: IconColorTypes =  'plain';

  @Input()
  styleIcon: IconTypes = 'send';

  private readonly sizeClasses;
  private readonly styleColorList;
  private readonly iconClasses;

  constructor() {

    this.sizeClasses = new Map<string, string>([
      ['normal','14px'],
      ['small','12px'],
      ['legend','10px']
    ])

    this.styleColorList = new Map<string, string>([
      ['primary', 'var(--system-primary)'],
      ['secondary', 'var(--system-secondary)'],
      ['success','var(--system-success)'],
      ['info','var(--system-info)'],
      ['warning','var(--system-warning)'],
      ['error','var(--system-error)'],
      ['plain','var(--system-black)'],
      ['legend','var(--system-legend)'],
      ['title','var(--system-title)'],
      ['special','var(--system-special)'],
      ['black','var(--system-black)'],
      ['white','var(--system-white)'],
    ])

    this.iconClasses = new Map<IconTypes, string>([
      ['send', PrimeIcons.SEND],
      ['yes', PrimeIcons.CHECK],
      ['no', PrimeIcons.TIMES],
      ['home', PrimeIcons.HOME],
      ['desktop', PrimeIcons.DESKTOP],
      ['folder', PrimeIcons.FOLDER],
      ['document', PrimeIcons.FILE_PDF],
      ['waiting', "pi pi-spin pi-spinner"],
      ['hour', PrimeIcons.HOURGLASS],
      ['book', PrimeIcons.BOOK]
    ])

  }

  public labelClassResolver(): string[] {
    return [this.sizeClasses.get(this.sizeFont) || ''];
  }

  public colorStyleResolver(): string {
      return this.styleColorList.get(this.styleColor) || '';
  }

  public iconClassResolver(): string {
    return this.iconClasses.get(this.styleIcon) || '';
  }


}
