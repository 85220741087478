import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';
import { QmListboxData } from './qm-listbox-data';

@Component({
  selector: 'qm-listbox[titleMessage][data]',
  template: `
    <qm-input-title [titleMessage]="titleMessage" [optionalField]="!this.required"></qm-input-title>
    <p-listbox [options]="data"
    optionLabel="name" optionDisabled="disabled" optionValue="value" [disabled]="disabled"
    [filter]="true" [filterMatchMode]="matchMode" [multiple]="multiple" [metaKeySelection]="false" [checkbox]="checkbox"
    [ngModel]="controlValue" (ngModelChange)="onModelChange($event)" (onChange)="onChangeValue($event)" (onClick)="onClick($event)" styleClass="mt-2"
    ></p-listbox>
    <qm-input-legend [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>

  `,
  styleUrls: ['./qm-listbox.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmListboxComponent),
        multi: true
    }
]
})
export class QmListboxComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue!: QmListboxData | undefined;
  onChanged = (_val: any) => {};
  onTouched = () => {}

  @Input()
  data: QmListboxData[] = [];

  @Input()
  matchMode: "contains" | "startsWith" | "endsWith" | "equals" | "notEquals" = "contains";

  @Input()
  multiple: boolean = false;

  @Input()
  checkbox: boolean = false;

  @Output()
  changeEvent = new EventEmitter<any>();

  @Output()
  clickEvent = new EventEmitter<any>();

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.setupLegendMessage();
  }

  writeValue(val: any): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(event: any) {
    this.onChanged(event);
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.changeEvent.emit(event);
  }

  onClick(event: any) {
    this.onTouched();
    this.clickEvent.emit(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }


}

