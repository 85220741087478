import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';

@Component({
  selector: 'qm-toggle-button[titleMessage][onLabel][offLabel]',
  template: `
    <qm-input-title [titleMessage]="titleMessage"></qm-input-title>
    <p-toggleButton [onLabel]="onLabel" [offLabel]="offLabel"
                    styleClass="w-full mt-2" [disabled]="disabled"
                    [(ngModel)]="controlValue"
                    [onIcon]="hasIcon? 'pi pi-check' : ''" [offIcon]="hasIcon? 'pi pi-times' : ''"
                    (onChange)="onChangeValue($event)"></p-toggleButton>
  `,
  styleUrls: ['./qm-toggle-button.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmToggleButtonComponent),
        multi: true
    }
]
})
export class QmToggleButtonComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue!: boolean | undefined;
  onChanged = (_val: boolean) => {};
  onTouched = () => {}

  @Input()
  onLabel!: string;

  @Input()
  offLabel!: string;

  @Input()
  hasIcon: boolean = false;

  @Output()
  changeEvent = new EventEmitter<any>();

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
  }

  writeValue(val: boolean): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(event: any) {
    this.onChanged(event);
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.onChanged(event.checked);
    this.changeEvent.emit(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
