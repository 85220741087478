import { Translation } from "@ngneat/transloco";

export let translationValues: Translation = {
    "en": {
      "startsWith": "Starts with",
      "contains": "Contains",
      "notContains": "Not contains",
      "endsWith": "Ends with",
      "equals": "Equals",
      "notEquals": "Not equals",
      "noFilter": "No Filter",
      "lt": "Less than",
      "lte": "Less than or equal to",
      "gt": "Greater than",
      "gte": "Greater than or equal to",
      "is": "Is",
      "isNot": "Is not",
      "before": "Before",
      "after": "After",
      "dateIs": "Date is",
      "dateIsNot": "Date is not",
      "dateBefore": "Date is before",
      "dateAfter": "Date is after",
      "clear": "Clear",
      "apply": "Apply",
      "matchAll": "Match All",
      "matchAny": "Match Any",
      "addRule": "Add Rule",
      "removeRule": "Remove Rule",
      "accept": "Yes",
      "reject": "No",
      "choose": "Choose",
      "upload": "Upload",
      "cancel": "Cancel",
      "dayNames": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      "dayNamesShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      "dayNamesMin": ["Su","Mo","Tu","We","Th","Fr","Sa"],
      "monthNames": ["January","February","March","April","May","June","July","August","September","October","November","December"],
      "monthNamesShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      "dateFormat": "mm/dd/yy",
      "firstDayOfWeek": 0,
      "today": "Today",
      "weekHeader": "Wk",
      "weak": "Weak",
      "medium": "Medium",
      "strong": "Strong",
      "passwordPrompt": "Enter a password",
      "emptyMessage": "No results found",
      "emptyFilterMessage": "No results found"
    },
    "pt": {
      "startsWith": "Começa com",
      "contains": "Contém",
      "notContains": "Não contém",
      "endsWith": "Termina com",
      "equals": "Igual",
      "notEquals": "Não igual",
      "noFilter": "Sem filtro",
      "lt": "Menor",
      "lte": "Menor ou igual",
      "gt": "Maior",
      "gte": "Maior ou igual",
      "is": "É",
      "isNot": "Não é",
      "before": "Antes",
      "after": "Depois",
      "dateIs": "Data é",
      "dateIsNot": "Data não é",
      "dateBefore": "Data é antes",
      "dateAfter": "Data é depois",
      "clear": "Limpar",
      "apply": "Aplicar",
      "matchAll": "Acertar todos",
      "matchAny": "Acertar qualquer um",
      "addRule": "Adicionar Regra",
      "removeRule": "Remover Regra",
      "accept": "Sim",
      "reject": "Não",
      "choose": "Escolher",
      "upload": "Importar",
      "cancel": "Cancelar",
      "dayNames": ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'],
      "dayNamesShort": ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb'],
      "dayNamesMin": ['D','S','T','Q','Q','S','S'],
      "monthNames": ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
      "monthNamesShort": ['Jan','Fev','Mar','Abr','Mai','Jun', 'Jul','Ago','Set','Out','Nov','Dez'],
      "dateFormat": "dd/mm/yy",
      "firstDayOfWeek": 0,
      "today": "Hoje",
      "weekHeader": "Semana",
      "weak": "Fraco",
      "medium": "Médio",
      "strong": "Forte",
      "passwordPrompt": "Entre com uma senha",
      "emptyMessage": "Nenhum resultado encontrado",
      "emptyFilterMessage": "Nenhum resultado encontrado"
  }
}
