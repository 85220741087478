import { Component, Input } from '@angular/core';

@Component({
  selector: 'qm-fieldset[titleMessage]',
  template:`
      <p-fieldset [toggleable]="toggleControl" [collapsed]="startCollapsed">
            <ng-template pTemplate="header">{{titleMessage}}</ng-template>
            <ng-content></ng-content>
      </p-fieldset>
  `,
  styleUrls: ['./qm-fieldset.component.css']
})
export class QmFieldsetComponent {

  @Input()
  titleMessage!: string;

  @Input()
  toggleControl: boolean = true;

  @Input()
  startCollapsed: boolean = false;

}
