import { Component, Input } from '@angular/core';
import { ChatState, MessageData } from './message.interface';
import { ChatUserData } from './chat-user.interface';
import { IconColorTypes, IconTypes } from '../qm-icon/qm-icon-types.interface';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Component({
  selector: 'qm-message[msgUser][msgData]',
  template: `
        <div class="chatMessage">

        <div *ngIf="msgData.sender; then senderMessage else receiverMessage"></div>

          <ng-template #senderMessage>
            <div class="chatMessageContainerSender">
                <div [class]="darkMode? 'chatMessageContentSender--dark mb-1' : 'chatMessageContentSender mb-1'">
                    <markdown emoji [id]="msgData.id" [data]="msgData.content" [inline]="true" ngPreserveWhiteSpaces />
                    <audio id="audio_{{msgData.id}}" *ngIf="msgData.mediacontent" [src]="msgData.mediacontent.mediapath | safe: 'url'" controls></audio>
                    <div class="flex justify-content-end mt-1">
                      <qm-label [labelMessage]="msgData.datetime | date: 'H:mm:ss'" sizeFont="small" [styleColor]="darkMode? 'white': 'legend'"></qm-label>
                    </div>
                  </div>
            </div>
          </ng-template>

          <ng-template #receiverMessage>
            <div class="flex align-items-end flex-row">
              <p-avatar [image]="msgUser.avatar" size="large" shape="circle" class="mr-2"></p-avatar>
                <div class="chatMessageContainerReceiver w-full">
                  <div [ngClass]="getchatMessageContentReceiverStyle()">
                    <markdown emoji [id]="msgData.id" [data]="msgData.content" [inline]="false" ngPreserveWhiteSpaces />
                    <audio id="audio_{{msgData.id}}" *ngIf="msgData.mediacontent" [src]="msgData.mediacontent.mediapath | safe: 'url'" controls [audioPlay]="playAudio"></audio>
                    <div class="m-1" *ngIf="msgData.options?.showDownload">
                      <a style="color: #fefeff" href="javascript:;" (click)="download(msgData.id)">Download</a>
                    </div>
                    <div class="flex justify-content-between mt-1">
                      <qm-label [labelMessage]="msgData.datetime | date: 'H:mm:ss'" sizeFont="small" [styleColor]="colorClassResolver()"></qm-label>
                      <qm-icon [styleIcon]="iconClassResolver()" sizeFont="small" [styleColor]="colorClassResolver()"></qm-icon>
                    </div>
                  </div>
                </div>
              </div>
          </ng-template>

        </div>
    `,
    styleUrls: ['./qm-chat-message.component.css']
})
export class QmChatMessageComponent {

  @Input()
  chatStyle: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'help' | 'error' = 'primary';

  @Input()
  darkMode: boolean = false;

  @Input()
  playAudio: boolean = false;

  @Input()
  msgUser!: ChatUserData;

  @Input()
  msgData!: MessageData;

  private readonly iconStatus;
  private readonly messageClass;

  constructor() {

    this.iconStatus = new Map<ChatState, IconTypes>([
      ['sending','waiting'],
      ['erase','hour'],
      ['error','no'],
      ['finish', 'yes']
    ])
    this.messageClass = new Map<string, string>([
      ['primary', 'chatMessageContentReceiver--primary mt-1'],
      ['secondary', 'chatMessageContentReceiver--secondary mt-1'],
      ['success','chatMessageContentReceiver--success mt-1'],
      ['warning','chatMessageContentReceiver--warning mt-1'],
      ['info','chatMessageContentReceiver--info mt-1'],
      ['help','chatMessageContentReceiver--help mt-1'],
      ['error','chatMessageContentReceiver--error mt-1']
    ])

  }

  public iconClassResolver(): IconTypes {
    return this.iconStatus.get(this.msgData.state) || 'yes';
  }

  public colorClassResolver(): IconColorTypes {
    return this.chatStyle === 'warning'? 'black' : 'white';
  }

  getchatMessageContentReceiverStyle() {
    return this.messageClass.get(this.chatStyle) || 'chatMessageContentReceiver--primary mt-1';
  }

  download(msgId: string) {
    var elt = document.getElementById(`datatable_${msgId}`);
    var wb = XLSX.utils.table_to_book(elt, { sheet: "dataquery", raw: true });
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
    const blob = new Blob([wbout], { type: 'application/vnd.ms-excel' });
    saveAs(blob, `dataquery_${msgId}.xlsx`);
   }
}
