import { Component, Input} from '@angular/core';

@Component({
  selector: 'qm-input-legend',
  template: `
    <div *ngIf="legendMessage" class="flex align-items-center mb-2">
    <i style='color: var(--system-error); font-size: 14px' class="pi pi-exclamation-circle mr-1" *ngIf="errorInput && legendMessage"></i>
    <div class="flex">
      <qm-label sizeFont='small' [errorInput]="errorInput" [labelMessage]="legendMessage"></qm-label>
    </div>
  </div>
  `,
  styleUrls: ['./qm-input-legend.component.css']
})
export class QmInputLegendComponent  {

  @Input()
  errorInput =  false;

  @Input()
  legendMessage = "";

}
