import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';

@Component({
  selector: 'qm-calendar',
  template: `
  <qm-input-title [titleMessage]="titleMessage" [optionalField]="!this.required"></qm-input-title>
  <p-calendar
  [ngModel]="controlValue"
  (ngModelChange)="onModelChange($event)"
  [showIcon]="showIcon" [showTime]="showTime"
  [showOnFocus]="false" [showOtherMonths]="true" [selectOtherMonths]="true"
  [readonlyInput]="readOnlyInput" [timeOnly]="timeOnly" [disabled]="disabled"
   [ngClass]="{'ng-dirty ng-invalid': invalidInput}"
  [style]="{'width':'100%','minWidth':'100%','margin-top': '0.5rem', 'margin-bottom' : '0.25rem'}"
  (onSelect)="onChangeValue($event)" (onBlur)="onTouched()" (onInput)="inputEvent.emit($event)"
  ></p-calendar>
  <qm-input-legend [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>
  `,
  styleUrls: ['./qm-calendar.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmCalendarComponent),
        multi: true
    }
]
})
export class QmCalendarComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue!: Date | undefined;
  onChanged: Function = (_val: Date) => {};
  onTouched: Function = () => {};

  @Input()
  showIcon: boolean = true;

  @Input()
  showTime: boolean = false;

  @Input()
  timeOnly: boolean = false;

  @Input()
  readOnlyInput: boolean = false;

  @Output()
  selectEvent = new EventEmitter<any>();

  @Output()
  inputEvent = new EventEmitter<any>();

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.setupLegendMessage();
  }

  writeValue(val: Date): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(event: any) {
    this.onChanged(event);
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.selectEvent.emit(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
