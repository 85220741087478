import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PowerBiComponent, PowerBiConfig,  } from "./powerbi.interface";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
  })
export class ChatPowerBiService {

    endPointSetupCompleted: string = environment.apiGatewayUrl + environment.endPointSufix + environment.endPointPbi;

    constructor(private http: HttpClient) {}

    public requestReportData(config: PowerBiConfig): Observable<PowerBiComponent> {
        return this.http.post<PowerBiComponent>(this.endPointSetupCompleted, config);
    }
}