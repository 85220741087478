import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';
import { QmMultiselectData } from './qm-multiselect-data';

@Component({
  selector: 'qm-multiselect[titleMessage]',
  template: `
    <qm-input-title [titleMessage]="titleMessage" [optionalField]="!this.required"></qm-input-title>
    <p-multiSelect [options]="data" display="chip" styleClass="w-full mt-2 mb-1"
    [ngModel]="controlValue" (ngModelChange)="onModelChange($event)" (onChange)="onChangeValue($event)"
    [ngClass]="{'ng-invalid': controlForm.invalid}" (onBlur)="onTouched()" [disabled]="disabled"
    [selectionLimit]="maxSelection" optionLabel="name" optionValue="value" optionDisabled="disabled"></p-multiSelect>
    <qm-input-legend [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>
  `,
  styleUrls: ['./qm-multiselect.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmMultiselectComponent),
        multi: true
    }
]
})
export class QmMultiselectComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue!: QmMultiselectData[] | undefined;
  onChanged = (_val: any) => {};
  onTouched = () => {}

  @Input()
  data: QmMultiselectData[] = [];

  @Input()
  maxSelection: number = Infinity;

  @Output()
  changeEvent = new EventEmitter<any>();

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.setupLegendMessage();
  }

  writeValue(val: any): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(event: any) {
    this.onChanged(event);
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.changeEvent.emit(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }



}
