import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { QMTableColumn, QMTableData } from './qm-table-data.interface';
import { QMTableOptions } from './qm-table-options.interface';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'qm-table[tableData][tableOptions]',
  template: `
      <p-table #dt [columns]="cols" [value]="tableData.values" styleClass="p-datatable-striped" responsiveLayout="scroll" [selectionPageOnly]="true"
      [paginator]="tableOptions.hasPaginator" [rows]="5" [rowsPerPageOptions]="[5, 10, 20]" [selectionMode]="tableOptions.selectionOptions?.selectionMode!"
      [showCurrentPageReport]="tableOptions.hasPageReport" [selection]="selectionData" (selectionChange)="selectionDataChange.emit($event)"
      (onRowSelect)="rowSelectEvent.emit($event)" (onRowUnselect)="rowUnselectEvent.emit($event)" (onHeaderCheckboxToggle)="pageSelectionEvent.emit($event)"
      currentPageReportTemplate="{first} de {last} | Total de Registro(s): {totalRecords}" [lazy]="lazy" (onLazyLoad)="lazyLoadEvent.emit($event)" [totalRecords]="10"
      [globalFilterFields]="globalFilterFields" [scrollable]="scrollable" [scrollHeight]="scrollHeight">
      <ng-container *ngIf="tableOptions.hasHeader">
        <ng-template pTemplate="caption">
          <div [class]="tableOptions.hasTitle || ref.children.length !== 0? 'flex align-items-center justify-content-between' : 'flex align-items-center justify-content-end' ">
            <div #ref><ng-content select="[headerButton]"></ng-content></div>
            <span *ngIf="tableOptions.hasTitle">{{tableOptions.tableTitle}}</span>
            <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt.filterGlobal($any($event.target).value, 'contains')" placeholder="Busca" />
            </span>
          </div>
        </ng-template>
      </ng-container>
    <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngIf="tableOptions.selectionOptions?.selectionMode === 'single'">
          </th>
          <th *ngIf="tableOptions.selectionOptions?.selectionMode === 'multiple'">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
           <th [style.min-width]="tableOptions.columnMinWidth" *ngFor="let col of columns">
            <div [ngClass]="alignmentResolver()">
              <div style="margin-right: 0.5rem">{{col.title}}</div>
              <p-sortIcon *ngIf="col.sortable" [pSortableColumn]="col.field" [field]="col.field"></p-sortIcon>
              <p-columnFilter [showAddButton]="false" [showOperator]="false"
              *ngIf="col.localFilter" [type]="col.type" [field]="col.field" display="menu"></p-columnFilter>
            </div>
          </th>
          <th></th>
        </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns">
        <tr>
          <td *ngIf="tableOptions.selectionOptions?.selectionMode === 'single'">
              <p-tableRadioButton [value]="rowData" [index]="rowIndex"></p-tableRadioButton>
          </td>
          <td *ngIf=" tableOptions.selectionOptions?.selectionMode === 'multiple'">
              <p-tableCheckbox [value]="rowData" [index]="rowIndex"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns">
            <div [ngClass]="alignmentResolver()">
              <span *ngIf="col.type !== 'date'">
                  {{rowData[col.field]}}
                </span>
                <span *ngIf="col.type === 'date'">
                  {{rowData[col.field] | date: 'dd/MM/yyyy HH:mm:ss'}}
                </span>
            </div>
          </td>
          <td [ngClass]="alignmentResolver()">
            <ng-container *ngTemplateOutlet="actionButtonsTemplate; context: {$implicit: rowData}"></ng-container>
          </td>
        </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
          <td [attr.colspan]="columns.length + 1">
              Nenhum registro encontrado
          </td>
      </tr>
    </ng-template>

  </p-table>
  `,
  styleUrls: ['./qm-table.component.css']
})

export class QmTableComponent implements OnInit {

  onChanged: Function = (_val: string) => {};
  onTouched: Function = () => {}

  @Input()
  lazy: boolean = false;

  @Input()
  tableData!: QMTableData<any>;

  @Input()
  tableOptions!: QMTableOptions;

  @Input()
  selectionData!: any

  @Input()
  scrollable!: boolean

  @Input()
  scrollHeight!: string

  @Output()
  selectionDataChange  = new EventEmitter<any>();

  @Output()
  rowSelectEvent = new EventEmitter<any>();

  @Output()
  rowUnselectEvent = new EventEmitter<any>();

  @Output()
  pageSelectionEvent = new EventEmitter<any>();

  @Output()
  lazyLoadEvent = new EventEmitter<LazyLoadEvent>();

  @ContentChild('actionButtons')
  actionButtonsTemplate!: TemplateRef<any>;

  cols!:tColumnHeader[];
  globalFilterFields!: string[];
  private readonly alignmentTypes;

  constructor() {
    this.cols = [];
    this.globalFilterFields = [];

    this.alignmentTypes = new Map<string, string>([
      ['center', 'flex align-itens-center justify-content-center'],
      ['left',   'flex align-itens-center justify-content-start'],
      ['right',  'flex align-itens-center justify-content-end'],
    ])
  }

  ngOnInit(): void {

    for (let tdata of this.tableData.columns) {

        let sortableTrait = (tdata.sortable?? true) && this.tableData.values.length !== 0;
        let localFilterTrait = (tdata.localFilter?? true) &&  this.tableData.values.length !== 0;

        this.cols.push({title: tdata.title,
                        field: tdata.field,
                        type: tdata.type,
                        sortable: sortableTrait,
                        localFilter: localFilterTrait});
        if (tdata.globalFilter !== undefined && tdata.globalFilter === true) {
          this.globalFilterFields.push(tdata.field);
        }
    }

  }

  public alignmentResolver(): string {
    return this.alignmentTypes.get(this.tableOptions.alignment!) || 'flex align-itens-center justify-content-center' ;
  }

}

type tColumnHeader = Pick<QMTableColumn, 'title' | 'field' | 'type' | 'sortable' | 'localFilter'>;

