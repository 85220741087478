import { Directive, Input } from "@angular/core";
import { FormControl, FormControlStatus } from "@angular/forms";

@Directive()
export abstract class BaseInputComponent {

  controlForm!: FormControl;
  disabled = false;
  required = false;

   /**
   * Define o conteúdo do título no componente
   */
  @Input()
  titleMessage: string = "";

   /**
   * Define o conteúdo padrão do rodapé no componente
   */
  @Input()
  defaultLegendMessage: string = "";

   /**
   * Define o conteúdo do rodapé no componente
   */
  @Input()
  legendMessage: string = "";

  /**
   * Define o conteúdo de uma mensagem de orientação no componente
   */
  @Input()
  placeholderMessage: string = "";

  get invalidInput() {
    return this.controlForm.invalid && this.controlForm.dirty;
  }

  get defaultRequiredMessage() {
    return this.titleMessage + ' é obrigatório';
  }

  get defaultInvalidMessage() {
    return this.titleMessage + ' é inválido';
  }

  setupLegendMessage() {
    this.defaultLegendMessage = this.legendMessage;
    this.controlForm.statusChanges.subscribe((_statusValue: FormControlStatus) => {

      if (_statusValue === 'VALID') {
         this.legendMessage = this.defaultLegendMessage;
         return;
      }

      if (this.controlForm.errors?.['required'] ) {
         this.legendMessage = this.defaultRequiredMessage
         return;
      }

      if (this.controlForm.errors?.['cnpjInvalid'] || this.controlForm.errors?.['cpfInvalid'] ) {
         this.legendMessage = this.defaultInvalidMessage
        return;
      }

      });
  }

}
