import { Component } from '@angular/core';


@Component({
  selector: 'app-auth-callback',
  template: ``,
})
export class AuthCallbackComponent {

  //private router: Router, private http: HttpClient
  constructor(
    
  ) {
    
   }
   
}
