import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'qm-button',
  template : `<button pButton
  [attr.disabled]="disabled ? true : null"
  [label]="labelMessage"
  [icon]="buttonIconResolver()"
  (click)="clickEvent.emit($event)"
  [style]="fillParent? 'width: 100%': ''"
  [ngClass]="buttonClassResolver()">
  </button>`,
  styleUrls: ['./qm-button.component.css']
})
export class QmButtonComponent implements OnInit {

  /**
   * Define a cor do botão
   */
  @Input()
  buttonType: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'help' | 'error' = 'primary';

   /**
   * Define o estilo visual do botão
   */
  @Input()
  buttonStyle: 'normal' | 'outlined' | 'text' = 'normal';

  /**
   * Define se o botão apenas terá ícone
  */
  @Input()
  onlyIcon = false;

  /**
   * Define se o botão terá sombra
  */
  @Input()
  shadow = false;

  /**
   * Define se o botão irá ignorar as cores (Apenas para estilo text)
  */
  @Input()
  ignoreColor = false;

  /**
   * Define se o botão tem estilo arredondado
  */
  @Input()
  rounded = false;

  /**
   * Define o ícone do botão
  */
  @Input()
  buttonIconAction: 'create' | 'save' | 'edit' | 'view' | 'delete' | 'export' | 'yes' | 'no' | 'logout' |
                    'search' | 'send' | 'arrow-up' | 'arrow-down' | 'waiting' | 'record' | 'pause' | 'clone' |'none' = 'none';
  /**
   * Define o rótulo do botão
  */
  @Input()
  labelMessage: string = "";

  /**
   * Define se o botão está desativado
  */
  @Input()
  disabled : boolean = false;

  /**
   * Define se o botão preenche totalmente a área útil do container
  */
  @Input()
  fillParent : boolean = false;

  @Output()
  clickEvent = new EventEmitter<Event>();

  private readonly typeActions;
  private readonly typeActionsOutlined;
  private readonly typeActionsText;
  private readonly iconActions;

  constructor() {

    this.typeActions = new Map<string, string>([
      ['primary', 'qm-button--primary'],
      ['secondary', 'qm-button--secondary'],
      ['success','qm-button--success'],
      ['info','qm-button--info'],
      ['warning','qm-button--warning'],
      ['help','qm-button--help'],
      ['error','qm-button--error']
    ])

    this.typeActionsOutlined = new Map<string, string>([
      ['primary', 'qm-button--primary-outlined'],
      ['secondary', 'qm-button--secondary-outlined'],
      ['success','qm-button--success-outlined'],
      ['info','qm-button--info-outlined'],
      ['warning','qm-button--warning-outlined'],
      ['help','qm-button--help-outlined'],
      ['error','qm-button--error-outlined']
      ])

    this.typeActionsText = new Map<string, string>([
      ['primary', 'qm-button--primary-text'],
      ['secondary', 'qm-button--secondary-text'],
      ['success','qm-button--success-text'],
      ['info','qm-button--info-text'],
      ['warning','qm-button--warning-text'],
      ['help','qm-button--help-text'],
      ['error','qm-button--error-text']
      ])

    this.iconActions = new Map<string, string>([
      ['create', 'pi pi-plus'],
      ['save', 'pi pi-save'],
      ['edit', 'pi pi-pencil'],
      ['clone', 'pi pi-clone'],
      ['view', 'pi pi-eye'],
      ['delete','pi pi-trash'],
      ['export','pi pi-chart-line'],
      ['yes', 'pi pi-check'],
      ['no', 'pi pi-times'],
      ['logout', 'pi pi-sign-out'],
      ['search', 'pi pi-search'],
      ['send', 'pi pi-send'],
      ['arrow-up', 'pi pi-arrow-up'],
      ['arrow-down', 'pi pi-arrow-down'],
      ['waiting', 'pi pi-spin pi-spinner'],
      ['record', 'pi pi-microphone'],
      ['pause', 'pi pi-pause']
    ])

    if (this.onlyIcon) {
      this.labelMessage = "";
    }

  }

  ngOnInit() {

    if (this.onlyIcon) {
      this.labelMessage = "";
    }


  }

  public buttonIconResolver(): string {
    return this.iconActions.get(this.buttonIconAction) || '';
  }

  public buttonClassResolver(): string[] {

    switch (this.buttonStyle) {
      case 'normal':
          return [this.typeActions.get(this.buttonType) || '',
                  this.shadow? 'shadow-2': '',
                  this.rounded? 'p-button-rounded': ''];

      case 'outlined':
          return [ this.typeActionsOutlined.get(this.buttonType) || '',
                   'p-button-outlined',
                   this.shadow? 'shadow-2': '',
                   this.rounded? 'p-button-rounded': ''];

      case 'text':
          return [ this.typeActionsText.get(this.buttonType) || '',
                  'p-button-text', this.shadow?
                  'shadow-2': '',
                  this.rounded? 'p-button-rounded': '',
                  this.ignoreColor? 'p-button-plain': ''];
    }

  }

}
