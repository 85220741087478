import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { PasswordModule } from 'primeng/password';
import { ChipsModule } from 'primeng/chips';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea'
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card'
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { PickListModule } from 'primeng/picklist';
import { FileUploadModule } from 'primeng/fileupload';
import { TagModule } from 'primeng/tag'
import { ListboxModule } from 'primeng/listbox'
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { InputSwitchModule } from 'primeng/inputswitch'
import { AccordionModule } from 'primeng/accordion'
import { DialogModule } from 'primeng/dialog'
import { EditorModule } from 'primeng/editor'
import { SplitButtonModule } from 'primeng/splitbutton'
import { ColorSketchModule } from 'ngx-color/sketch';
import {TooltipModule} from 'primeng/tooltip';
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { AvatarModule } from 'primeng/avatar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { QmButtonComponent } from './qm-button/qm-button.component';
import { QmLabelComponent } from './qm-label/qm-label.component';
import { QmInputComponent } from './qm-input/qm-input.component';
import { QmInputPasswordComponent } from './qm-input-password/qm-input-password.component';
import { QmInputNumberComponent } from './qm-input-number/qm-input-number.component';
import { QmChipsComponent } from './qm-chips/qm-chips.component';
import { QmRadioButtonGroupComponent } from './qm-radiobutton-group/qm-radiobutton-group.component';
import { QmInputTextareaComponent } from './qm-input-textarea/qm-input-textarea.component';
import { QmInputLegendComponent } from './qm-input-legend/qm-input-legend.component';
import { QmSelectComponent } from './qm-select/qm-select.component';
import { QmConfirmDialogComponent } from './qm-confirmdialog/qm-confirmdialog.component';
import { QmInputTitleComponent } from './qm-input-title/qm-input-title.component';
import { QmCheckboxGroupComponent } from './qm-checkbox-group/qm-checkbox-group.component';
import { QmAutocompleteComponent } from './qm-autocomplete/qm-autocomplete.component';
import { QmCalendarComponent } from './qm-calendar/qm-calendar.component';
import { QmToastComponent } from './qm-toast/qm-toast.component';
import { QmToggleButtonComponent } from './qm-toggle-button/qm-toggle-button.component';
import { QmSliderComponent } from './qm-slider/qm-slider.component';
import { QmInputMaskComponent } from './qm-input-mask/qm-input-mask.component';
import { QmConfirmpopupComponent } from './qm-confirmpopup/qm-confirmpopup.component';
import { QmTableComponent } from './qm-table/qm-table.component';
import { QmCardComponent } from './qm-card/qm-card.component';
import { QmBreadcrumbComponent } from './qm-breadcrumb/qm-breadcrumb.component';
import { QmFieldsetComponent } from './qm-fieldset/qm-fieldset.component';
import { QmTabComponent, QmTabviewComponent } from './qm-tabview/qm-tabview.component';
import { QmPicklistComponent } from './qm-picklist/qm-picklist.component';
import { QmFileuploadComponent } from './qm-fileupload/qm-fileupload.component';
import { QmTagComponent } from './qm-tag/qm-tag.component';
import { QmListboxComponent } from './qm-listbox/qm-listbox.component';
import { QmMultiselectComponent } from './qm-multiselect/qm-multiselect.component';
import { QmOrderlistComponent } from './qm-orderlist/qm-orderlist.component';
import { QmInputSwitchComponent } from './qm-input-switch/qm-input-switch.component';
import { QmAccordionComponent, QmAccTabComponent } from './qm-accordion/qm-accordion.component';
import { QmDialogComponent } from './qm-dialog/qm-dialog.component';
import { QmEditorComponent } from './qm-editor/qm-editor.component';
import { QmSplitButtonComponent } from './qm-split-button/qm-split-button.component';
import { QmColorpickerComponent } from './qm-colorpicker/qm-colorpicker.component';
import { AutocompleteHighlightPipe } from './qm-autocomplete/autocomplete-highlight.pipe'
import { AudioPlayDirective } from './qm-chat/chat-audio.directive';
import { QmChatComponent } from './qm-chat/qm-chat.component';
import { LoaderComponent } from './qm-loader/loader.component';
import { MarkdownComponent, MarkdownModule } from 'ngx-markdown';
import { QmChatMessageComponent } from './qm-chat/qm-chat-message.component';
import { QmIconComponent } from './qm-icon/qm-icon.component';
import { SafePipe } from './qm-chat/chat-safe-pipe';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { QmTreeComponent } from './qm-tree/qm-tree.component';
import { TreeModule } from 'primeng/tree';

@NgModule({
  declarations: [
    QmButtonComponent,
    QmLabelComponent,
    QmIconComponent,
    QmInputComponent,
    QmInputTextareaComponent,
    QmInputPasswordComponent,
    QmInputNumberComponent,
    QmInputMaskComponent,
    QmInputLegendComponent,
    QmInputTitleComponent,
    QmChipsComponent,
    QmRadioButtonGroupComponent,
    QmCheckboxGroupComponent,
    QmAutocompleteComponent,
    QmCalendarComponent,
    QmSelectComponent,
    QmToastComponent,
    QmToggleButtonComponent,
    QmSliderComponent,
    QmConfirmDialogComponent,
    QmTableComponent,
    QmConfirmpopupComponent,
    QmBreadcrumbComponent,
    QmCardComponent,
    QmFieldsetComponent,
    QmTabviewComponent,
    QmTabComponent,
    QmPicklistComponent,
    QmFileuploadComponent,
    QmTagComponent,
    QmListboxComponent,
    QmMultiselectComponent,
    QmOrderlistComponent,
    QmInputSwitchComponent,
    QmAccordionComponent,
    QmAccTabComponent,
    QmDialogComponent,
    QmEditorComponent,
    QmSplitButtonComponent,
    QmColorpickerComponent,
    QmChatMessageComponent,
    QmChatComponent,
    LoaderComponent,
    SafePipe,
    QmTreeComponent,
    AutocompleteHighlightPipe,
    AudioPlayDirective
  ],
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    InputMaskModule,
    PasswordModule,
    ChipsModule,
    RadioButtonModule,
    CheckboxModule,
    DropdownModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    AutoCompleteModule,
    CalendarModule,
    ToastModule,
    ToggleButtonModule,
    SliderModule,
    TableModule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    BreadcrumbModule,
    FieldsetModule,
    TabViewModule,
    PickListModule,
    FileUploadModule,
    HttpClientModule,
    TagModule,
    ListboxModule,
    MultiSelectModule,
    OrderListModule,
    InputSwitchModule,
    AccordionModule,
    DialogModule,
    EditorModule,
    SplitButtonModule,
    ColorSketchModule,
    TooltipModule,
    AvatarModule,
    NgScrollbarModule,
    MatProgressSpinnerModule,
    MarkdownModule,
    TreeModule,
    PowerBIEmbedModule
  ],
  exports: [
    QmButtonComponent,
    QmLabelComponent,
    QmInputComponent,
    QmIconComponent,
    QmInputTextareaComponent,
    QmInputPasswordComponent,
    QmInputNumberComponent,
    QmInputLegendComponent,
    QmInputMaskComponent,
    QmInputTitleComponent,
    QmChipsComponent,
    QmRadioButtonGroupComponent,
    QmCheckboxGroupComponent,
    QmAutocompleteComponent,
    QmCalendarComponent,
    QmSelectComponent,
    QmToastComponent,
    QmToggleButtonComponent,
    QmSliderComponent,
    QmConfirmDialogComponent,
    QmTableComponent,
    QmConfirmpopupComponent,
    QmBreadcrumbComponent,
    QmCardComponent,
    QmFieldsetComponent,
    QmTabviewComponent,
    QmTabComponent,
    QmPicklistComponent,
    QmFileuploadComponent,
    QmTagComponent,
    QmListboxComponent,
    QmMultiselectComponent,
    QmOrderlistComponent,
    QmInputSwitchComponent,
    QmAccordionComponent,
    QmAccTabComponent,
    QmDialogComponent,
    QmEditorComponent,
    QmSplitButtonComponent,
    QmChatComponent,
    QmColorpickerComponent,
    LoaderComponent,
    QmTreeComponent,
    MarkdownComponent
  ]
})
export class UikitModule { }
