import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';
import { QmAutocompleteData } from './qm-autocomplete-data';

@Component({
  selector: 'qm-autocomplete[inputSuggestions]',
  template: `
  <qm-input-title [titleMessage]="titleMessage" [optionalField]="!this.required && !ignoreOptional"></qm-input-title>
  <span class="p-input-icon-left" style="width: 100%">
    <i class="pi pi-search"></i>
  <p-autoComplete
    [ngModel]="controlValue"
    (ngModelChange)="onModelChange($event)"
    [suggestions]="inputSuggestions"
    [placeholder]="placeholderMessage"
    (completeMethod)="searchMethod($event)"
    (onSelect)="onChangeValue($event)"
    (onBlur)="onTouched()"
    [dropdown]="enableDropdown"
    [forceSelection]="true"
    [disabled]="disabled"
    field="label"
    [inputStyle]="{'width':'100%'}"
    [ngClass]="{'ng-dirty ng-invalid': invalidInput}"
    [style]="{'width':'100%', 'margin-top': '0.5rem', 'margin-bottom' : '0.25rem'}">
    <ng-template let-value pTemplate="item">
          <span [innerHTML]="value.label | highlight: inputValue"></span>
      </ng-template>
    </p-autoComplete>
  </span>
  <qm-input-legend [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>
  `,
  styleUrls: ['./qm-autocomplete.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmAutocompleteComponent),
        multi: true
    }
]
})
export class QmAutocompleteComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  inputValue!: string;
  controlValue!: unknown;
  onChanged = (_val: any) => {};
  onTouched = () => {}

  @Input()
  ignoreOptional: boolean = false;

  @Input()
  inputSuggestions!: QmAutocompleteData[];

  @Input()
  enableDropdown = false;

  @Output()
  searchEvent = new EventEmitter<any>();

  @Output()
  selectEvent = new EventEmitter<any>();

  searchMethod(event: any) {
    this.inputValue = event.query;
    this.searchEvent.emit(event);
  }

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.setupLegendMessage();
  }

  writeValue(val: any): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(event: any) {
    this.onChanged(event);
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.selectEvent.emit(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
