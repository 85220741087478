import { Component, forwardRef, Inject, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';

@Component({
  selector: 'qm-input-password[titleMessage]',
  template: `
    <qm-input-title [titleMessage]="titleMessage" [optionalField]="!this.required"></qm-input-title>
    <p-password
    [ngModel]="controlValue"
    (ngModelChange)="onModelChange($event)"
    [toggleMask]="togglePasswordMask"
    [feedback]="feedbackOverlay"
    (blur)="onTouched()"
    [disabled]="disabled"
    [ngClass]="{'ng-dirty ng-invalid': invalidInput}"
    styleClass="w-full p-input-icon-right mt-2 mb-1"
    [inputStyle]="{'width':'100%'}"></p-password>
    <qm-input-legend [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>
  `,
  styleUrls: ['./qm-input-password.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmInputPasswordComponent),
        multi: true
    }
  ]
})
export class QmInputPasswordComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue!: string | undefined;
  onChanged = (_val: string) => {};
  onTouched = () => {}

  @Input()
  togglePasswordMask: boolean = false;

  @Input()
  feedbackOverlay: boolean = true;

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.setupLegendMessage();
  }

  writeValue(val: string): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(event: any) {
    this.onChanged(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
