import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { Observable } from 'rxjs';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  endPointUserCompleted: string = environment.apiGatewayUrl + environment.endPointSufix + environment.endPointUser;

  constructor(private http: HttpClient) {}

  getUserById(id: number): Observable<User> {
    return this.http.get<User>(`${this.endPointUserCompleted}/${id}`);
  }

  getUsers(): Observable<User[]>{
    return this.http.get<User[]>(this.endPointUserCompleted);
  }

  save(user: User) : Observable<User> {
    return this.http.post<User>(this.endPointUserCompleted, user);
  }

  replace(user: User) : Observable<any> {
    return this.http.put<User>(`${this.endPointUserCompleted}/${user.id}`, user);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.endPointUserCompleted}/${id}`);
  }
}
