import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QmPicklistData } from './qm-picklist-data';

@Component({
  selector: 'qm-picklist[sourceList][sourceHeaderTitle][targetList][targetHeaderTitle]',
  template: `

  <p-pickList [source]="sourceList" [sourceHeader]="sourceHeaderTitle"
              [target]="targetList" [targetHeader]="targetHeaderTitle"
              [responsive]="false"
              (onMoveToTarget)="moveToTargetEvent.emit($event)"
              (onMoveToSource)="moveToSourceEvent.emit($event)">
    <ng-template let-rowData pTemplate="item">
        <div>
            <div> {{rowData.label}} </div>
        </div>
    </ng-template>
</p-pickList>

  `,
  styleUrls: ['./qm-picklist.component.css']
})
export class QmPicklistComponent {

  @Input()
  sourceList: QmPicklistData[] = [];

  @Input()
  targetList: QmPicklistData[] = [];

  @Input()
  sourceHeaderTitle!: string;

  @Input()
  targetHeaderTitle!: string;

  @Output()
  moveToTargetEvent = new EventEmitter<any>();

  @Output()
  moveToSourceEvent = new EventEmitter<any>();

}
