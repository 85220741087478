import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';

@Component( {
  selector: 'qm-acctab[headerTitle]',
  template: `
  <ng-template>
    <ng-content></ng-content>
  </ng-template>
  `
})
export class QmAccTabComponent {

  @Input()
  headerTitle: string = "";

  @Input()
  selectedTab: boolean = false;

  @Input()
  disabled: boolean = false;

  @ViewChild(TemplateRef, { static: true }) templateRef!: TemplateRef<void>;
}


@Component({
  selector: 'qm-accordion',
  template: `
    <p-accordion [(activeIndex)]="tabIndex" (onOpen)="openEvent.emit($event)" (onClose)="closeEvent.emit($event)">
      <ng-container *ngFor="let tc of tabContents">
        <p-accordionTab [header]="tc.headerTitle" [selected]="tc.selectedTab" [disabled]="tc.disabled">
          <ng-container *ngTemplateOutlet="tc.templateRef"> </ng-container>
        </p-accordionTab>
      </ng-container>
    </p-accordion>
  `,
  styleUrls: ['./qm-accordion.component.css']
})
export class QmAccordionComponent {

  @Input()
  tabIndex : number = 0;

  @Output()
  openEvent = new EventEmitter<any>();

  @Output()
  closeEvent = new EventEmitter<any>();

  @ContentChildren(QmAccTabComponent, { descendants: true }) tabContents!: QueryList<QmAccTabComponent>;

}
