import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';

@Component({
  selector: 'qm-slider',
  template: `
    <qm-input-title [titleMessage]="titleMessage"></qm-input-title>
    <p-slider [min]="minValue" [max]="maxValue" [step]="stepValue"
    [ngModel]="controlValue"
    [disabled]="disabled"
    (ngModelChange)="onModelChange($event)"
    [disabled]="disabled" styleClass="w-full mt-3 mb-3"
    (onChange)="updateSliderLabel($event)"></p-slider>
    <div class="flex justify-content-center">
      <qm-label [labelMessage]="sliderValue.toString()" sizeFont="normal" styleFont="bold" styleColor="primary"></qm-label>
    </div>
  `,
  styleUrls: ['./qm-slider.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmSliderComponent),
        multi: true
    }
]
})
export class QmSliderComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue!: number;
  sliderValue: number = 0;
  onChanged = (_val: number) => {};
  onTouched = () => {}

  @Input()
  minValue: number = 0;

  @Input()
  maxValue: number = 100;

  @Input()
  stepValue: number = 1;

  @Output()
  changeEvent = new EventEmitter<any>();

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
  }

  writeValue(val: number): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(event: any) {
    this.onChanged(event);
  }

  updateSliderLabel(event:any) {
    this.onTouched();
    this.sliderValue = event.value;
    this.changeEvent.emit(event)
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
