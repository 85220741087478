import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject } from 'rxjs';
import { authCodeFlowConfig } from './auth-config';
import { environment } from 'src/environments/environment';
import { Group } from '../users/interfaces/group';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

private group!: Group;

private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

constructor(
  private authService: OAuthService, 
  private router: Router) {
  
  this.authService.configure(authCodeFlowConfig);
  this.authService.setupAutomaticSilentRefresh();

  this.authService.events.subscribe(_ => {
    this.isAuthenticatedSubject$.next(this.hasValidToken());
  });
  this.isAuthenticatedSubject$.next(this.hasValidToken());
  
}

 public runOAuthLogin() : Promise<void> {
  return this.authService.loadDiscoveryDocument()
  .then(() => this.authService.tryLoginCodeFlow())
  .then(() => this.isDoneLoadingSubject$.next(true))
  .catch(() => this.isDoneLoadingSubject$.next(true));
}


public login(targetUrl?: string) {
  this.authService.initLoginFlow(targetUrl || this.router.url); 
}
public getGroupUser(){
  return this.group;
}
public setGroupUser(groupUser: Group){
  this.group =  groupUser;
}
public logout() {this.authService.revokeTokenAndLogout().then((_) => window.location.href = environment.apiGatewayUrl + '/logout');}
public hasValidToken() {return this.authService.hasValidAccessToken() && this.authService.hasValidIdToken(); }
public get accessToken() { return this.authService.getAccessToken(); }
public get refreshToken() { return this.authService.getRefreshToken(); }
public get idToken() { return this.authService.getIdToken(); }
public get idClaims() {return this.authService.getIdentityClaims()}
public get isNaoLicenciado() { return this.idClaims['sub'] === 'naoLicenciado'}
public get isAdmin() {
  return (this.group == undefined || this.group == null) ? true : this.group.name === 'Administrador';
  //return this.idClaims['sub'].startsWith('Admin')
}
public get isEditRegra() { 
  //return this.idClaims['sub'].startsWith('Admin') || this.idClaims['sub'].startsWith('Adm Regras')
  return (this.group == undefined || this.group == null) ? true : (this.group.name === 'Administrador' || this.group.name === 'Adm Regras');
}

}
