import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { translationValues } from "../uikit/base-translation";
import { PrimeNGConfig } from 'primeng/api';
import { AuthService } from './auth/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-root',
  template: `
    <app-header *ngIf="showHeader"></app-header>
    <qm-loader></qm-loader>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {

  constructor(private translocoService: TranslocoService,
              private authService: AuthService,
              private readonly router: Router,
              private translationPrimeNG: PrimeNGConfig) {}

  showHeader: boolean = false;

  ngOnInit(): void {
    this.setupLanguage();
    this.visibleHeaderRoute();
  }

  visibleHeaderRoute() : void {
    this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(e => e as NavigationEnd)
    ) .subscribe(({url}) => {
      this.showHeader = this.authService.hasValidToken() && "/chat" !== url;
    })
  }

  setupLanguage(): void {
    let userLanguage = navigator.language.split('-')[0];
    this.translocoService.setActiveLang(userLanguage);
    this.translationPrimeNG.setTranslation(translationValues[userLanguage]);
  }

}
