import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Group } from '../../users/interfaces/group';

@Injectable({
    providedIn: 'root'
  })
export class GroupService {
  endPointgroupCompleted: string =environment.apiGatewayUrl + environment.endPointSufix + environment.endPointGroup;

  constructor(private http: HttpClient) {}

  getGroupUsuario(): Observable<Group> {
    return this.http.get<Group>(`${this.endPointgroupCompleted}/getGroup`);
  }
  getGroupById(id : number): Observable<Group> {
    return this.http.get<Group>(`${this.endPointgroupCompleted}/${id}`);
  }
  getGroupByUser(): Observable<Group> {
    return this.http.get<Group>(`${this.endPointgroupCompleted}/groupUser`);
  }
  getAll(): Observable<Group[]>{
    return this.http.get<Group[]>(this.endPointgroupCompleted);
  }

  save(group: Group) : Observable<Group> {
    return this.http.post<Group>(this.endPointgroupCompleted, group);
  }

  replace(group: Group) : Observable<any> {
    return this.http.put<Group>(`${this.endPointgroupCompleted}/${group.id}`, group);
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`${this.endPointgroupCompleted}/${id}`);
  }
}