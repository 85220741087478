import { Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'qm-confirmpopup',
  template: `
    <p-confirmPopup [key]="idPopup" styleClass="qm-popup-content"></p-confirmPopup>
  `,
  styleUrls: ['./qm-confirmpopup.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class QmConfirmpopupComponent {

  @Input()
  idPopup!: string;


}
