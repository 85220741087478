import { Injectable, Injector } from '@angular/core';
import { FormControl, NgControl, NgModel, FormControlName, FormGroupDirective } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ControlService {

getConstructor(injector: Injector) : Function {
  return injector.get(NgControl).constructor;
}

getControlType(injector: Injector): FormControl<any> {
  const injectedControl = injector.get(NgControl);
  switch (injectedControl.constructor) {
    case NgModel: {
      const { control } = injectedControl as NgModel;
      return control;
    }
    case FormControlName: {
      return injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
    }
  }
    throw Error('Controle inválido');
}

}
