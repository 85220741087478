import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseInputComponent } from '../base-input-component';
import { ControlService } from '../control.service';
@Component({
  selector: 'qm-input-number',
  template: `
    <qm-input-title [titleMessage]="titleMessage" [optionalField]="!this.required"></qm-input-title>
    <p-inputNumber
    [ngModel]="controlValue"
    (ngModelChange)="onModelChange($event)"
    [min]="minValue" [max]="maxValue" [step]="stepValue"
    [showButtons]="showButtons"
    [minFractionDigits]="minFraction"
    [maxFractionDigits]="maxFraction"
    (onBlur)="onTouched()"
    (onInput)="onChangeValue($event)"
    [prefix]="prefixStr"
    [suffix]="suffixStr"
    [disabled]="disabled"
    [ngClass]="{'ng-dirty ng-invalid': invalidInput}"
     class="w-full mt-2 mb-1"> </p-inputNumber>
    <qm-input-legend [legendMessage]="legendMessage" [errorInput]="invalidInput"></qm-input-legend>

  `,
  styleUrls: ['./qm-input-number.component.css'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QmInputNumberComponent),
        multi: true
    }
]
})
export class QmInputNumberComponent extends BaseInputComponent implements OnInit, ControlValueAccessor {

  controlValue!: number | undefined;
  onChanged = (_val: number) => {};
  onTouched = () => {}

  @Input()
  minValue: number = 0;

  @Input()
  maxValue: number = 100;

  @Input()
  stepValue: number = 1;

  @Input()
  minFraction: number = 0;

  @Input()
  maxFraction: number = 3;

  @Input()
  showButtons: boolean = true;

  @Input()
  prefixStr: string = "";

  @Input()
  suffixStr: string = "";

  @Output()
  inputEvent = new EventEmitter<any>();

  constructor(@Inject(Injector) private injector: Injector, public ctrlService: ControlService) {
    super();
  }

  public ngOnInit(): void {
    this.controlForm = this.ctrlService.getControlType(this.injector);
    this.required = this.controlForm.hasValidator(Validators.required);
    this.setupLegendMessage();
  }

  writeValue(val: number): void {
    this.controlValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(event: any) {
    this.onChanged(event);
  }

  onChangeValue(event: any) {
    this.onTouched();
    this.inputEvent.emit(event);
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
