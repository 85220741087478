import { Injectable } from '@angular/core';
import { TranslocoService, Translation } from '@ngneat/transloco';
import { FilterService } from 'primeng/api';
import { ObjectUtils } from 'primeng/utils';
import { Observable } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localeBR from "@angular/common/locales/br";
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitializationService {

constructor(private translocoService: TranslocoService,
            private authService: AuthService,
            private filterService: FilterService) {
    registerLocaleData(localeBR, "pt-br");
  }

  init(): Promise<any> {
    this.setupFilter();
    this.setupTranslations().subscribe();
    return this.authService.runOAuthLogin();
  }


  private setupFilter() {
    this.filterService.register('contains', (value: string, filter:string): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }

      if (value === undefined || value === null) {
          return false;
      }

      let filterValue = ObjectUtils.removeAccents(filter.toString().trim()).toLocaleLowerCase();
      let stringValue = ObjectUtils.removeAccents(value.toString().trim()).toLocaleLowerCase();
      return stringValue.indexOf(filterValue) !== -1;
  });
  }

  private setupTranslations(): Observable<Translation> {
    const currentLang = (window.navigator as Navigator).language.split('-')[0];
    return this.translocoService.load(currentLang);
  }

}
