import { APP_INITIALIZER, NgModule, Provider, SecurityContext } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TemplateModule } from './template/template.module';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { HomeComponent } from './components/home/home.component';
import { UserService } from './users/services/user.service';
import { TranslocoRootModule } from './transloco-root.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { OAuthModule }  from 'angular-oauth2-oidc';
import { AppInitializationService } from './app-initialization.service';
import { LoaderInterceptor } from 'src/uikit/qm-loader/loader.interceptor';
import { LoaderService } from 'src/uikit/qm-loader/loader.service';

export const loadApplicationInit: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: (appInit: AppInitializationService) => () => appInit.init(),
  deps: [AppInitializationService]
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    OAuthModule.forRoot({
        resourceServer: {
          allowedUrls: [environment.apiGatewayUrl + environment.endPointSufix],
          sendAccessToken: true
        }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TemplateModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoRootModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.STYLE,
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          breaks: true,
          pedantic: false,
        },
      },
    })
  ],
  providers: [
    UserService,
    LoaderService,
    loadApplicationInit,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
