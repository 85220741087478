import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[audioPlay]',
})
export class AudioPlayDirective implements OnInit {

  @Input() 
  audioPlay: boolean = false;
  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    this.changeAudioPlay();
  }

  changeAudioPlay() {
    (<HTMLAudioElement>this.el.nativeElement).autoplay = this.audioPlay;
  }
 
}